import { Alert, Button, Card, CardBody, Dialog, Input } from '@material-tailwind/react'
import React, { useEffect, useMemo, useState } from 'react'
import { PiMagnifyingGlassBold } from "react-icons/pi";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils'
import Register from './Register';
import API from '../API/API';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}
//=======================TABLE COLUMNS=====================
const columns = [
  {
      id: 'username', 
      label: 'Username',
      disablePadding: true,
      minWidth: 200
  },
  {
      id: 'email', 
      label: 'Email',
      disablePadding: true,
      minWidth: 150
  },
  {
      id: 'division', 
      label: 'Division',
      disablePadding: true,
      minWidth: 150
  },
  {
      id: 'status', 
      label: 'Status',
      disablePadding: true,
      minWidth: 150
  },
  {
      id: 'lastOnline', 
      label: 'Last Login',
      disablePadding: true,
      minWidth: 150
  }
]

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {columns?.map((column) => (
            <TableCell
              key={column.id}
              align={'left'}
              sortDirection={orderBy === column.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
              >
                <p className='font-bold rounded-lg'>
                    {column.label}
                </p>
                {orderBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

const AgentList = ({socket, mode}) => {
    const [agentList, setAgentList] = useState([])

    //=====================ERROR HANDLING===================
    const [alert, setAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')

    const handleError = (message) =>{
      setAlert(true)
      setAlertMessage(message)
    }

    if(alert){
      setTimeout(() => {
        setAlert(false)
      }, 5000);
    }

    //========================PAGINATION===================
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    //========================TABLE SORTING===================
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('createdAt');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - agentList?.length) : 0;

    const visibleRows = useMemo(
        () =>
            stableSort(agentList, getComparator(order, orderBy))?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, agentList],
    );

    useEffect(() => {
      //=========================GET AGENT LIST==============
      const getAgentList = async () =>{
        try {
          const response = await API.get('/api/agentList')
          if(response.status === 200){
            setAgentList(response.data)
          }
        } catch (error) {
          console.log(error.response)
          if(error.message !== 'Network Error'){
            handleError(error.response.data?.message)
          }else{
            handleError('Network Error')
          }
        }
      }
      getAgentList()
    }, []);

    //=======================LIVE SEARCH=======================
    const [q, setQ] = useState("");
    const filteredRows = visibleRows?.filter(item =>
      item.username.toLowerCase().includes(q.toLowerCase()) ||
      item.email.toLowerCase().includes(q.toLowerCase()) ||
      item.role.toLowerCase().includes(q.toLowerCase())
    );
    const displayedRows = q ? filteredRows : visibleRows;

    //======================DIALOG ADD AGENT===============
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen((cur) => !cur);

  return (
    <div className='w-full'>
      {mode === 'home' ? '' :
        <div className='flex justify-between'>
            <p className='text-3xl font-bold text-center md:text-start'>
                Agents Management
            </p>
        </div>
      }
      <div className=''>
          <Card className='h-full w-full' shadow={false}>
              <CardBody className='p-2'>
                <div className='rounded-none flex md:flex-row flex-col justify-between items-center gap-5'>
                    <div className="w-full md:w-72 mt-5">
                        <Input
                            label="Search Agent"
                            icon={<PiMagnifyingGlassBold className="h-5 w-5" />}
                            value={q}
                            onChange={e => setQ(e.target.value)}
                        />
                    </div>
                    {mode === 'home' ? '' :
                    <Button className='md:w-[30%] w-full mt-5' onClick={handleOpen}>
                        Create New Agent
                    </Button>
                    }
                </div>
                  <TableContainer 
                  style={{
                    height: '100%'
                  }}>
                      <Table stickyHeader aria-label="sticky table">
                          <EnhancedTableHead
                              order={order}
                              orderBy={orderBy}
                              onRequestSort={handleRequestSort}
                              rowCount={agentList?.length}
                          />
                          <TableBody>
                              {displayedRows?.map((row, index) => {
                                  return (
                                  <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row.id}
                                      sx={{ cursor: 'pointer' }}
                                  >
                                      <TableCell>{row.username}</TableCell>
                                      <TableCell>{row.email}</TableCell>
                                      <TableCell><p className='uppercase'>{row.division}</p></TableCell>
                                      <TableCell ><p className={`capitalize ${row.status === 'online' ? 'text-green-400' : 'text-red-500'}`}>{row.status}</p></TableCell>
                                      <TableCell><p className='uppercase'>{new Date(row.lastOnline).toLocaleString('en-GB')}</p></TableCell>
                                  </TableRow>
                                  );
                              })}
                              {emptyRows > 0 && (
                                  <TableRow
                                  style={{
                                      height: (53) * emptyRows,
                                  }}
                                  >
                                  <TableCell colSpan={6} />
                                  </TableRow>
                              )}
                              </TableBody>
                      </Table>
                  </TableContainer>
                  <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={agentList?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                  />
              </CardBody>
          </Card>
      </div>
      <Dialog
        size="md"
        open={open}
        handler={handleOpen}
        className="shadow-none overflow-auto"
      >
        <Register mode={handleOpen}/>
      </Dialog>
      <div className='absolute md:right-5 z-10 bottom-5 p-5 md:w-[21%] w-full'>
        <Alert open={alert} color="red">
          <p className='font-bold'>
            Error
          </p>
          <p>
            Message : {alertMessage}
          </p>
        </Alert>
      </div>
  </div>
  )
}

export default AgentList