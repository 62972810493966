import { Button, Card, CardBody } from '@material-tailwind/react'
import React, { useState } from 'react'
import {SendTemplate, ShorcutTemplate, TemplateList} from '../components'
import useDocumentTitle from '../useDocumentTitle'

const Broadcast = ({socket}) => {
    useDocumentTitle('Templates - ChatApp')
    const [openTemplateList, setOpenTemplateList] = useState(true)
    const [openSendTemplate, setOpenSendTemplate] = useState(false)
    const [openShortcut, setOpenShortcut] = useState(false)

    const handleOpenTemplateList = () =>{
        setOpenTemplateList(true)
        setOpenSendTemplate(false)
        setOpenShortcut(false)
    }

    const handleOpenSendTemplate = () =>{
        setOpenTemplateList(false)
        setOpenSendTemplate(true)
        setOpenShortcut(false)
    }

    const handleOpenShortcut = () =>{
        setOpenTemplateList(false)
        setOpenSendTemplate(false)
        setOpenShortcut(true)
    }
  return (
    <div className='flex flex-col md:flex-row mx-5 h-full gap-5 pb-5'>
        <Card className='md:w-[25%] w-full text-black'>
            <CardBody className='space-y-8 w-full'>
                <div className='space-y-5'>
                    <div className='text-3xl font-bold'>
                        Broadcast
                        <hr className='w-full h-0.5 rounded-full border-2 mt-3'/>
                    </div>
                    <Button className='h-14 rounded-3xl items-center w-full' onClick={handleOpenTemplateList}>
                        Broadcast Templates
                    </Button>
                    <Button className='h-14 rounded-3xl items-center w-full' onClick={handleOpenSendTemplate}>
                        Send Broadcast
                    </Button>
                </div>
                <div className='space-y-5'>
                    <div className='text-3xl font-bold'>
                        Shortcut
                        <hr className='w-full h-0.5 rounded-full border-2 mt-3'/>
                    </div>
                    <Button className='h-14 rounded-3xl items-center w-full' onClick={handleOpenShortcut}>
                        Shortcut Templates
                    </Button>
                </div>
            </CardBody>
        </Card>
        <Card className='md:w-[75%] w-full'>
            {openTemplateList && (
                <TemplateList socket={socket} />
            )}
            {openSendTemplate && (
                <SendTemplate socket={socket}/>
            )}
            {openShortcut && (
                <ShorcutTemplate socket={socket}/>
            )}
        </Card>
    </div>
  )
}

export default Broadcast