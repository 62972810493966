import { Alert, Button, Card, CardBody, CardHeader, Dialog, Input } from '@material-tailwind/react'
import React, { useEffect, useMemo, useState } from 'react'
import { PiMagnifyingGlassBold } from "react-icons/pi";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import CreateTemplate from './CreateTemplate';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}
//=======================TABLE COLUMNS=====================
const columns = [
  {
      id: 'name', 
      label: 'Template Name',
      disablePadding: true,
      minWidth: 200
  },
  {
      id: 'status', 
      label: 'Approval Status',
      disablePadding: true,
      minWidth: 150
  },
  {
      id: 'division', 
      label: 'Division',
      disablePadding: true,
      minWidth: 150
  },
  {
      id: 'type', 
      label: 'Template Type',
      disablePadding: true,
      minWidth: 150
  },
  {
      id: 'language', 
      label: 'Language',
      disablePadding: true,
      minWidth: 150
  },
  {
      id: 'category', 
      label: 'Category',
      disablePadding: true,
      minWidth: 150
  },
  {
      id: 'createdAt', 
      label: 'Created At',
      disablePadding: true,
      minWidth: 200,
      format: (value) => new Date(value).toLocaleString('en-GB')
  },
]

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {columns?.map((column) => (
            <TableCell
              key={column.id}
              align={'left'}
              sortDirection={orderBy === column.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
              >
                <p className='font-bold rounded-lg'>
                    {column.label}
                </p>
                {orderBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

const Template = ({socket}) => {
    const [templates, setTemplates] = useState([])
    //========================PAGINATION===================
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    //========================TABLE SORTING===================
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('createdAt');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - templates?.length) : 0;

    const visibleRows = useMemo(
        () =>
            stableSort(templates, getComparator(order, orderBy))?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, templates],
    );
    //=====================ERROR HANDLING===================
    const [alert, setAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')

    const handleError = (message) =>{
      setAlert(true)
      setAlertMessage(message)
    }

    if(alert){
      setTimeout(() => {
      setAlert(false)
      }, 5000);
    }
    //=========================GET TEMPLATE LIST==============
    useEffect(() => {
      try {
        socket.emit("allTemplates", {},(response) =>{
            if(response){
                setTemplates(response)
            }
        })
      } catch (error) {
        handleError(error)
      }
    }, [socket]);

    //=======================LIVE SEARCH=======================
    const [q, setQ] = useState("");
    const filteredRows = visibleRows?.filter(item =>
      item.name.toLowerCase().includes(q.toLowerCase()) ||
      item.status.toLowerCase().includes(q.toLowerCase()) ||
      item.division.toLowerCase().includes(q.toLowerCase()) ||
      item.type.toLowerCase().includes(q.toLowerCase()) ||
      item.language.toLowerCase().includes(q.toLowerCase()) ||
      item.category.toLowerCase().includes(q.toLowerCase())
    );
    const displayedRows = q ? filteredRows : visibleRows;

    //======================DIALOG ADD TEMPLATE===============
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen((cur) => !cur);

  return (
    <div className='mx-5 my-5'>
        <div className='flex justify-between'>
            <p className='text-3xl font-bold text-black'>
              Broadcast Templates
            </p>
        </div>
        <div className=''>
            <Card className='h-full' shadow={false}>
                <CardHeader floated={false} shadow={false} className="rounded-none flex justify-between items-center md:flex-row flex-col">
                    <div className="w-full md:w-72 mt-5">
                        <Input
                            label="Search Templates"
                            icon={<PiMagnifyingGlassBold className="h-5 w-5" />}
                            value={q}
                            onChange={e => setQ(e.target.value)}
                        />
                    </div>
                    <Button className='mt-5 md:w-[25%] w-full' onClick={handleOpen}>
                        Create Template
                    </Button>
                </CardHeader>
                <CardBody className='p-3'>
                    <TableContainer 
                    style={{
                      minHeight: '0px'
                    }}>
                        <Table stickyHeader aria-label="sticky table">
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={templates?.length}
                            />
                            <TableBody>
                                {displayedRows?.map((row, index) => {
                                    return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell><p className='capitalize'>{row.status}</p></TableCell>
                                        <TableCell><p className='capitalize'>{row.division}</p></TableCell>
                                        <TableCell><p className='capitalize'>{row.type}</p></TableCell>
                                        <TableCell>{row.language}</TableCell>
                                        <TableCell><p className='capitalize'>{row.category}</p></TableCell>
                                        <TableCell>{new Date(row.createdAt).toLocaleString('en-GB')}</TableCell>
                                    </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                    >
                                    <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                                </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={templates?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardBody>
            </Card>
        </div>
        <Dialog
          size="xl"
          open={open}
          handler={handleOpen}
          className="shadow-none overflow-auto"
        >
          <CreateTemplate socket={socket}/>
        </Dialog>
        <div className='absolute md:right-5 z-10 bottom-5 p-5 md:w-[21%] w-full'>
          <Alert open={alert} color="red">
            <p className='font-bold'>
              Error
            </p>
            <p>
              Message : {alertMessage}
            </p>
          </Alert>
        </div>
    </div>
  )
}

export default Template