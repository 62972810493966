import React, { useState } from "react";
import logo from '../img/logo-chat-gw.png'
import { IoMdChatbubbles, IoMdSettings } from "react-icons/io";
import { TbLogout } from "react-icons/tb";
import { IoClose, IoMenuOutline } from "react-icons/io5";
import {
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Drawer,
  Card,
  Tooltip,
  Alert
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useStateContext } from "../contexts/ContextProvider";
import API from "../API/API";
import { IoMdContacts } from "react-icons/io";
import { BiSolidMessageEdit } from "react-icons/bi";

 
export default function Sidebar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { setLoggedIn, setCurrentUser } = useStateContext();
  const navigate = useNavigate()
  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
	//=====================ERROR HANDLING===================
	const [alert, setAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')

	const handleError = (message) =>{
	setAlert(true)
	setAlertMessage(message)
	}

	if(alert){
	setTimeout(() => {
		setAlert(false)
	}, 5000);
	}
  const Logout = () => {
    try {
      	API.post('/api/auth/signout')
		closeDrawer()
		navigate('/')
		setCurrentUser(null);
		setLoggedIn(false);
    } catch (error) {
	if(error.message !== 'Network Error'){
		handleError(error.response.data?.message)
	}else{
		handleError('Network Error')
	}
    }
  } 

  return (
    <>
		<div className="flex justify-between p-3 items-center h-full w-full bg-white">
			<Link to='/'>
				<div className="flex justify-center">
					<img
					src={logo}
					alt="logo"
					className="h-10 w-10"
					/>
				</div>
			</Link>
			<Link to='/'>
				<Typography variant="h5" color="blue-gray">
					Chat App
				</Typography>
			</Link>
			<Tooltip content="Menu" placement="right">
				<IconButton variant="text" size="lg" onClick={openDrawer}>
					{isDrawerOpen ? (
					<IoClose  className="h-8 w-8 stroke-2" />
					) : (
					<IoMenuOutline className="h-8 w-8 stroke-2" />
					)}
				</IconButton>
			</Tooltip>
		</div>
		<Drawer open={isDrawerOpen} onClose={closeDrawer} className="bg-gray-200" >
			<Card color="transparent" shadow={false} className="h-full p-4">
				<div className="flex justify-center gap-4 p-4">
					<Link to='/' onClick={closeDrawer}>
						<div className="flex justify-center">
							<img
							src={logo}
							alt="logo"
							className="h-8 w-8"
							/>
						</div>
						<Typography variant="h5" color="blue-gray">
							Chat App
						</Typography>
					</Link>
				</div>
				<List>
					<Link to='/chat' onClick={closeDrawer}>
						<ListItem>
							<ListItemPrefix>
								<IoMdChatbubbles className="h-5 w-5" />
							</ListItemPrefix>
							Inbox
						</ListItem>
					</Link>
					<Link to='/template' onClick={closeDrawer}>
						<ListItem>
							<ListItemPrefix>
								<BiSolidMessageEdit className="h-5 w-5" />
							</ListItemPrefix>
							Broadcast
						</ListItem>
					</Link>
					<Link to='/customers' onClick={closeDrawer}>
						<ListItem>
							<ListItemPrefix>
								<IoMdContacts className="h-5 w-5" />
							</ListItemPrefix>
							Customers
						</ListItem>
					</Link>
					<Link to='/settings' onClick={closeDrawer}>
						<ListItem>
							<ListItemPrefix>
								<IoMdSettings className="h-5 w-5" />
							</ListItemPrefix>
							Settings
						</ListItem>
					</Link>
					<Link onClick={Logout}>
						<ListItem>
							<ListItemPrefix>
								<TbLogout className="h-5 w-5" />
							</ListItemPrefix>
							Log Out
						</ListItem>
					</Link>
				</List>
			</Card>
		</Drawer>
		<div className='absolute md:right-5 z-10 bottom-5 p-5 md:w-[21%] w-full'>
			<Alert open={alert} color="red">
			<p className='font-bold'>
				Error
			</p>
			<p>
				Message : {alertMessage}
			</p>
			</Alert>
		</div>
    </>
  );
}