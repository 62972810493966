import React, { useState, useRef, useEffect } from "react";
import { Textarea} from '@material-tailwind/react';

const AutoResizeTextarea = ({ value, onChange, ...rest }) => {
  const textareaRef = useRef(null);
  const [currentRows, setCurrentRows] = useState(0);
  const handleTextareaChange = (e) => {
    if (textareaRef.current) {
      const { scrollHeight, clientHeight, style } = textareaRef.current;
      const rows = Math.min(5, Math.ceil(scrollHeight / clientHeight));
      setCurrentRows(rows);
      style.overflowY = rows === 5 ? "auto" : "hidden";
    }

    if (onChange) {
      onChange(e);
    }
  };
  useEffect(() => {
    if (textareaRef.current) {
      const { scrollHeight, clientHeight } = textareaRef.current;
      const rows = Math.min(5, Math.ceil(scrollHeight / clientHeight));
      setCurrentRows(rows === 0 ? 1 : rows);
    }
  }, [value]);

  return (
    <Textarea
      ref={textareaRef}
      value={value}
      onChange={handleTextareaChange}
      rows={currentRows}
      {...rest}
    />
  );
};

export default AutoResizeTextarea;
