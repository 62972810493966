import React, { useEffect, useState } from 'react'
import { ChatInfo, ChatList } from '../components';
import ChatRoom from '../components/ChatRoom';
import { useStateContext } from '../contexts/ContextProvider';
import { IoIosChatboxes } from "react-icons/io";
import useDocumentTitle from '../useDocumentTitle'
import { Alert } from '@material-tailwind/react';

const Chat = ({socket}) => {
  const { activeListChat, setActiveListChat, screenSize, currentUser } = useStateContext();
  const [chatList, setChatList] = useState([]);
  const [showInfo, setShowInfo] = useState(true)
  const [selectedChat, setSelectedChat] = useState(null)
  const [transferConfirm, setTransferConfirm] = useState(false)
  const totalUnserved = chatList.filter((chat) => chat.status.toLowerCase() === 'unserved' ).length
  useDocumentTitle(totalUnserved !== 0 ? `${totalUnserved} Unserved conversation - ChatApp` : 'Conversation - ChatApp')

  const getChatList = () =>{
    try {
      socket.emit("allConversation", currentUser,(response) =>{
        if(response){
          setChatList(response.data)
        }
      })
    } catch (error) {
      handleError(error)
    }
  }

  useEffect(() => {
    const getChatList = () =>{
      try {
        socket.emit("allConversation", currentUser,(response) =>{
          if(response){
            setChatList(response.data)
          }
        })
      } catch (error) {
        handleError(error)
      }
    }
    getChatList();
    setSelectedChat(null);
  }, [socket, currentUser]);
  
  useEffect(() => {
    const getChatList = () =>{
      try {
        socket.emit("allConversation", currentUser,(response) =>{
          if(response){
            setChatList(response.data)
          }
        })
      } catch (error) {
        handleError(error)
      }
    }
    socket.on("refreshChatMessage", (message) =>{
      getChatList();
    });
  }, [socket, currentUser, selectedChat])

  useEffect(() => {
    const getChatList = () =>{
      try {
        socket.emit("allConversation", currentUser,(response) =>{
          if(response){
            setChatList(response.data)
          }
        })
      } catch (error) {
        handleError(error)
      }
    }
    socket.on("refreshConversationTransfer", (response) =>{
      getChatList()
      if(currentUser.agent === response.newAgentId){
        setTransferConfirm(true)
      }
    });
  }, [socket, currentUser])

  //=====================ERROR HANDLING===================
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const handleError = (message) =>{
    setAlert(true)
    setAlertMessage(message)
  }

  if(alert){
    setTimeout(() => {
    setAlert(false)
    }, 5000);
  }
  
  const handleSelectChat = (chat) => {
    try {
      socket.emit('updateConversation', {
        notification: 0,
        conversationId: chat.id
      }, (response) =>{
        // console.log(response)
      });
      if(selectedChat){
        setSelectedChat(null);
      }
      setSelectedChat(chat);
      setActiveListChat(false);
    } catch (error) {
      handleError(error)
    }
  };


  // useEffect(() => {
  //   setSelectedChat(null);
  //   setActiveListChat(true);
  // }, [screenSize]);

  useEffect(() => {
      if(screenSize >= 960){
        setShowInfo(true)
      }else{
        setShowInfo(false)
      }
  }, [screenSize]);

  const handleInfo = () =>{
    if(showInfo){
      setShowInfo(false)
    }else{
      setShowInfo(true)
    }
  }

  const getCustomer = () =>{
    socket.emit("assignConversation", currentUser,(response) =>{
      if(response){
        getChatList()
        if(response.status === 200){
          handleSelectChat(response.data)
        }else{
          alert(response.message)
        }
        setActiveListChat(false);
      }
    })
  } 
  const handleDialogConfirm = () => setTransferConfirm(!transferConfirm)

  if(transferConfirm){
    setTimeout(() => {
      setTransferConfirm(false)
    }, 2000);
  }

  return(
    <div className='flex h-full'>
      <title>test title</title>
      {screenSize < 769 ? (
        activeListChat ? (
          <ChatList chats={chatList} onSelectChat={handleSelectChat} currentUser={currentUser} getCustomer={getCustomer}/>
        ) : showInfo ? (
          <ChatInfo selectedChat={selectedChat} handleInfo={handleInfo} socket={socket} setSelectedChat={setSelectedChat} getChatList={getChatList} />
        ): (
          <ChatRoom setSelectedChat={setSelectedChat} selectedChat={selectedChat} currentUser={currentUser} socket={socket} handleInfo={handleInfo}/>
        )
      ) : (
        <>
          <ChatList chats={chatList} onSelectChat={handleSelectChat} currentUser={currentUser} getCustomer={getCustomer}/>
          {selectedChat ? (
            <>
            <ChatRoom selectedChat={selectedChat} setSelectedChat={setSelectedChat} currentUser={currentUser} socket={socket} handleInfo={handleInfo} showInfo={showInfo}/>
            {showInfo && (
              <ChatInfo selectedChat={selectedChat} handleInfo={handleInfo} socket={socket} setSelectedChat={setSelectedChat} getChatList={getChatList}/>
            )}
            </>
          ) : (
            <div className="flex flex-col justify-center items-center w-[80%] p-4 opacity-50 font-bold">
              <IoIosChatboxes className='text-9xl' />
              Select a chat to start messaging
            </div>
          )}
        </>
      )}
      <div className='absolute lg:right-5 z-10 bottom-5 p-5 lg:w-[21%] w-full'>
        <Alert open={transferConfirm} onClose={handleDialogConfirm}>
          You have new transferred conversation
        </Alert>
        <Alert open={alert} color="red">
          <p className='font-bold'>
            Error
          </p>
          <p>
            Message : {alertMessage}
          </p>
        </Alert>
      </div>
    </div>
  )
};

export default Chat