import { Card, CardBody, Typography } from '@material-tailwind/react'
import React, { useState } from 'react'
import { TfiMenuAlt } from "react-icons/tfi";
import { IoCloseCircle } from "react-icons/io5";
import { MdOutlineReply } from "react-icons/md";
import { FaExternalLinkAlt } from "react-icons/fa";
const MessagePreview = ({headerPreview, bodyPreview, footerPreview, headerFormat, messageTime, size, action}) => {
    const [showAction, setShowAction] = useState(false)
    const handleShowAction = () => setShowAction(!showAction)
    return (
    <Card className={`p-5 md:${size ? size : 'w-1/3'} w-full mt-5 md:mt-0 text-black`}>
        <CardBody className='p-0'>
            {size ? '': (
                <>
                <Typography variant='h5'>
                    Preview
                </Typography>
                <hr className='h-0.5 bg-gray-700 border-0 rounded my-5'/>
                </>
            )}
            <div className="bg-[#EEF0E5] bg-chatbg break-words rounded-xl min-h-[400px] pb-5">
                {/* <UserProfile username='EnterKomputer' profile={logo}/> */}
                {headerPreview || bodyPreview || footerPreview ? (
                <div className={`flex items-end justify-start`}>
                    <div className={`max-w-xs rounded-lg bg-[#597E52] text-white mx-2 mt-5 min-w-24 min-h-10 ${headerFormat === 'TEXT' || !headerFormat ? 'px-4 py-2' : 'p-1'}`}>
                        <div className='whitespace-pre-line'>
                            <div className='font-bold mb-1'>
                                {headerPreview}
                            </div>
                            <p className={`font-normal ${headerFormat === 'TEXT' || !headerFormat ? 'mx-0' : 'mx-2'} mb-1`}>
                                {bodyPreview}
                            </p>
                            <p className={`font-thin text-sm opacity-80 italic mb-1 ${headerFormat === 'TEXT' || !headerFormat ? 'mx-0' : 'mx-2'}`}>
                                {footerPreview}
                            </p>
                        </div>
                        {action && (
                            <>
                                {Array.isArray(action.buttons) && (action.buttons.map((button, index) => {
                                    return(
                                    <div className='w-full flex flex-col justify-center' key={index}>
                                        <hr className='w-full opacity-50'/>
                                        <div className='flex justify-center items-center w-full text-light-blue-300 text-sm hover:text-light-blue-100 cursor-pointer select-none p-2'>
                                            <MdOutlineReply className='text-xl'/>
                                            <p>{button.reply?.title}</p>
                                        </div>
                                    </div>
                                    )
                                }))}
                                {action.parameters ? (
                                    <div className='w-full flex flex-col justify-center'>
                                        <hr className='w-full opacity-50'/>
                                        <div className='text-light-blue-300 hover:text-light-blue-100 cursor-pointer select-none p-2'>
                                            <a href={action.parameters.url} target="_blank" rel="noopener noreferrer" className='flex gap-2 justify-center items-center w-full text-sm'>
                                                <FaExternalLinkAlt className='text-base'/>
                                                <p>{action.parameters.display_text}</p>
                                            </a>
                                        </div>
                                    </div>
                                ): !Array.isArray(action.buttons) ? (
                                    <div className='w-full flex flex-col justify-center gap-1'>
                                        <hr className='text-white h-0.5 w-full'/>
                                        <div className='w-full flex justify-center items-center hover:bg-white hover:bg-opacity-10'>
                                            <div className='w-full flex gap-1 items-center justify-center text-light-blue-300 text-sm hover:text-light-blue-100 cursor-pointer select-none ' onClick={handleShowAction}>
                                                <TfiMenuAlt className=''/>
                                                <p className=''>
                                                    {action.button}
                                                </p>
                                            </div>
                                        </div>
                                        {showAction && (
                                        <div className='transition-all absolute break-words rounded-xl h-full w-full p-5 left-0 top-0 select-none'>
                                            <div className='flex rounded-lg h-full bg-black bg-opacity-10 w-full items-end '>
                                                <div className='rounded-lg h-[80%] bg-gray-800 w-full p-2 overflow-y-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]'>
                                                    <div className='flex w-full'>
                                                        <div className='flex w-full justify-center'>
                                                            <p className='ml-5'>
                                                                {action.button}
                                                            </p>
                                                        </div>
                                                        <div className='w-[10%] flex items-center'>
                                                            <IoCloseCircle className='opacity-70 hover:opacity-100 text-xl cursor-pointer' onClick={handleShowAction}/>
                                                        </div>
                                                    </div>
                                                    {action.sections && action.sections.map((section, index) => {
                                                        return(
                                                        <div className='mt-2 my-5' key={index}>
                                                            <p className='text-xs font-semibold opacity-80 mb-1'>
                                                                {section.title}
                                                            </p>
                                                            <hr className='w-full opacity-50'/>
                                                            {section.rows.map((row, index) =>{
                                                                return(
                                                                    <div key={row.id}>
                                                                        <div className='hover:bg-white hover:bg-opacity-10 py-2 cursor-pointer'>
                                                                            <p className='text-base'>
                                                                                {row.title}
                                                                            </p>
                                                                            <p className='text-xs opacity-50'>
                                                                                {row.description}
                                                                            </p>
                                                                        </div>
                                                                        <hr className='w-full opacity-50'/>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        )
                                                    })}
                                                    <div className='flex justify-center items-center w-full'>
                                                        <p className='text-xs opacity-50 mb-2'>
                                                            Tap an item to select it
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                ): ''}
                            </>
                        )}
                    </div>
                </div>
                ) : ''}
            </div>
        </CardBody>
    </Card>
  )
}

export default MessagePreview