import React, { useEffect, useMemo, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import MessagePreview from './MessagePreview';
import { Button, Card, CardBody, Option, Dialog, Input, Select, Textarea, Alert } from '@material-tailwind/react'
import { IoMdInformationCircle } from 'react-icons/io';
import { IoAddCircleOutline,IoRemoveCircleOutline  } from "react-icons/io5";
import { PiMagnifyingGlassBold } from "react-icons/pi";

const InputCustom = ({value, disabled, onChange, label, maxLength}) =>{
  return(
    <Input
      className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
      labelProps={{
        className: "hidden",
      }}
      containerProps={{ className: "min-w-[100px]" }}
      disabled={disabled}
      value={value}
      onChange={onChange}
      label={label}
      maxLength={maxLength}
    />
  )
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
//=======================TABLE COLUMNS=====================
const columns = [
    {
        id: 'keyword', 
        label: 'Keyword',
        disablePadding: true,
        minWidth: 100
    },
    {
        id: 'createdAt', 
        label: 'Created At',
        disablePadding: true,
        minWidth: 100,
        format: (value) => new Date(value).toLocaleString('en-GB')
    },
]

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={'left'}
              sortDirection={orderBy === column.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
              >
                <p className='font-bold rounded-lg'>
                    {column.label}
                </p>
                {orderBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

const AutoReplySetting = ({socket}) => {
    const [autoReplyList, setAutoReplyList] = useState([])
    const [response, setResponse] = useState(null)
    //========================PAGINATION===================
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    //========================TABLE SORTING===================
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('createdAt');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - autoReplyList.length) : 0;

    const visibleRows = useMemo(
        () =>
            stableSort(autoReplyList, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, autoReplyList],
    );
    useEffect(() => {
        socket.emit("getAllAutoReplies", {},(response) =>{
            if(response){
                setAutoReplyList(response.data)
            }
        })
    }, [socket]);

    //=======================LIVE SEARCH=======================
    const [q, setQ] = useState("");
    const [keyword, setKeyword] = useState('')
    const [autoReplyId, setAutoReplyId] = useState('')
    const filteredRows = visibleRows.filter(item =>
        item.keyword.toLowerCase().includes(q.toLowerCase())
    );
    const displayedRows = q ? filteredRows : visibleRows;

    const handleClickList = (value) =>{
        setResponse(JSON.parse(value.response))
        setKeyword(value.keyword)
        setAutoReplyId(value.id)
    }

    //==================CREATE NEW AUTOREPLY=======================
    const [openDialog, setOpenDialog] = useState(false)
    const [type, setType] = useState('')
    const [headerText, setHeaderText] = useState('')
    const [bodyText, setBodyText] = useState('')
    const [footerText, setFooterText] = useState('')
    const [url, setUrl] = useState('')
    const [displayText, setDisplayText] = useState('')
    const [paramButtons, setParamButtons] = useState([])
    const [edit, setEdit] = useState(false)
    
    const initialData = {
      button: '',
      sections: []
    }
    const [data, setData] = useState(initialData);

    const handleOpenCreate = (mode) => {
      setOpenDialog(!openDialog)
      if(mode === 'edit'){
        setEdit(true)
        setType(response.interactive.type)
        if(response.interactive.type === 'cta_url'){
          setBodyText(response.interactive.body.text)
          setFooterText(response.interactive.footer.text)
          setDisplayText(response.interactive.action.parameters.display_text)
          setUrl(response.interactive.action.parameters.url)
        }
        if(response.interactive.type === 'button'){
          setBodyText(response.interactive.body.text)
          setParamButtons(response.interactive.action.buttons)
        }
        if(response.interactive.type === 'list'){
          setDisplayText(response.interactive.action.button)
          setHeaderText(response.interactive.header.text)
          setBodyText(response.interactive.body.text)
          setFooterText(response.interactive.footer.text)
          setData(response.interactive.action)
        }
      }else{
        setEdit(false)
        setKeyword('')
        setHeaderText('')
        setBodyText('')
        setFooterText('')
        setDisplayText('')
        setUrl('')
        setParamButtons([])
        setType('')
        setData(initialData)
      }
    }
    const handleSelectType = (value) => {
      setType(value)
      setHeaderText('')
      setBodyText('')
      setFooterText('')
      setDisplayText('')
      setUrl('')
      setParamButtons([])
      setData(initialData)
    }
    let param = {
      keyword: keyword,
      response: {
        type: type,
      }
    }
    if(headerText){
      param.response.header = {
        type: 'text',
        text: headerText
      }
    }
    if(bodyText){
      param.response.body = {
        text: bodyText
      }
    }
    if(footerText){
      param.response.footer = {
        text: footerText
      }
    }
    if(type === 'cta_url'){
      param.response.action = {
        name: 'cta_url',
        parameters: {
          display_text : displayText,
          url: url
        }
      }
    }
    if(type === 'button'){
      param.response.action = {
        buttons: paramButtons
      }
    }
    if(type === 'list'){
      param.response.action = {
        button: displayText,
        sections: data.sections
      }
    }

  const handleAddParamsButton = () => {
    setParamButtons([...paramButtons, ''])
  }

  const handleRemoveParamsButton = (index) => {
    setParamButtons(button=> button.filter((s,i)=>(i !== index)))
  }
  const handleParamsButtonInputChange = (index, value) => {
    const newButtonInputChange = [...paramButtons];
    newButtonInputChange[index] = {
      type: 'reply',
      reply: {
        id: new Date().getTime().toString(),
        title: value
      }
    }
    setParamButtons(newButtonInputChange);
  };

  const addSection = () => {
    setData((prevData) => ({
      ...prevData,
      sections: [
        ...prevData.sections,
        {
          title: '',
          rows: []
        }
      ]
    }));
  };

  const removeSection = (index) => {
    setData((prevData) => ({
      ...prevData,
      sections: prevData.sections.filter((_, i) => i !== index)
    }));
  };

  const addRow = (sectionIndex) => {
    setData((prevData) => ({
      ...prevData,
      sections: prevData.sections.map((section, i) => {
        if (i === sectionIndex) {
          return {
            ...section,
            rows: [
              ...section.rows,
              {
                id: new Date().getTime().toString(),
                title: "",
                description: ""
              }
            ]
          };
        }
        return section;
      })
    }));
  };

  const handleSectionTitleChange = (event, sectionIndex) => {
    const newSections = [...data.sections];
    newSections[sectionIndex].title = event.target.value;
    setData({ ...data, sections: newSections });
  };

  const handleRowFieldChange = (event, sectionIndex, rowIndex, field) => {
    const newSections = [...data.sections];
    newSections[sectionIndex].rows[rowIndex][field] = event.target.value;
    setData({ ...data, sections: newSections });
  };
  const removeRow = (sectionIndex, rowIndex) => {
    setData((prevData) => ({
      ...prevData,
      sections: prevData.sections.map((section, i) => {
        if (i === sectionIndex) {
          return {
            ...section,
            rows: section.rows.filter((_, j) => j !== rowIndex)
          };
        }
        return section;
      })
    }));
  };

  //=====================ERROR HANDLING===================
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const handleError = (message) =>{
    setAlert(true)
    setAlertMessage(message)
  }

  if(alert){
    setTimeout(() => {
    setAlert(false)
    }, 5000);
  }

  const handleCreateAutoReply = () =>{
    if(edit){
      param.id = autoReplyId
      try {
        socket.emit("editAutoReplies", param ,(response) =>{
          if(response){
              if(response.status === 'success'){
                handleOpenCreate()
              }
          }
        })
      } catch (error) {
        handleError(error.message)
      }
    }else{
      try {
        socket.emit("createAutoReplies", param,(response) =>{
          if(response){
            if(response.status === 'success'){
              handleOpenCreate()
            }
          }
        })
      } catch (error) {
        handleError(error.message)
      }
    }
}

  return (
    <div className='w-full space-y-10'>
        <div className='flex justify-between items-end md:flex-row flex-col gap-2'>
            <div className='w-full md:w-72 space-y-3 flex items-start justify-end flex-col'>
              <p className='text-2xl font-bold'>
                  Auto Reply 
              </p>
              <div className="w-full">
                <Input
                    label="Search"
                    icon={<PiMagnifyingGlassBold className="h-5 w-5" />}
                    value={q}
                    onChange={e => setQ(e.target.value)}
                />
              </div>
            </div>
            <Button className='md:w-[30%] w-full' onClick={() => handleOpenCreate('create')}>
                Create auto reply
            </Button>
        </div>
        <div className='flex justify-between gap-10'>
            <div className='w-full'>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={autoReplyList.length}
                        />
                        <TableBody>
                            {displayedRows.map((row, index) => {
                                return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell onClick={() => handleClickList(row)}>{row.keyword}</TableCell>
                                    <TableCell onClick={() => handleClickList(row)}>{new Date(row.createdAt).toLocaleString('en-GB')}</TableCell>
                                </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                style={{
                                    height: (53) * emptyRows,
                                }}
                                >
                                <TableCell colSpan={6} />
                                </TableRow>
                            )}
                            </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={autoReplyList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
            {response && (
                <div className='w-[40%] space-y-5'>
                  <div>
                    <Button className='w-full' onClick={()=> handleOpenCreate('edit')}>
                      Edit Auto Reply
                    </Button>
                  </div>
                  <MessagePreview size={'w-full'} headerPreview={response.interactive?.header?.text} bodyPreview={response.interactive?.body?.text}
                  footerPreview={response.interactive?.footer?.text} action={response.interactive.action}
                  />
                </div>
            )}
        </div>
        <Dialog
          size="lg"
          open={openDialog}
          handler={handleOpenCreate}
          className="bg-transparent shadow-none"
        >
          <Card className="mx-auto w-full min-h-[30vh]">
            <CardBody className="flex flex-col gap-4">
              <div className='w-full px-2'>
                <div className='flex md:flex-row flex-col justify-between items-center w-full py-3'>
                  <p className='text-2xl font-bold'>
                    Create New Auto Reply
                  </p>
                  <Button className='w-full md:w-[20%]' disabled={!type || !keyword || !param.response.action} onClick={handleCreateAutoReply}>
                    Save
                  </Button>
                </div>
                <div className='flex w-full md:flex-row flex-col justify-between gap-5 max-h-[80vh] overflow-y-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]'>
                  <div className='w-full mt-5 space-y-5'>
                    <div className=''>
                      <p>
                        Keyword
                      </p>
                      <InputCustom value={keyword} onChange={(e) => setKeyword(e.target.value)}/>
                    </div>
                    <div>
                      <p>
                        Type
                      </p>
                      <Select value={type} onChange={handleSelectType} className='!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900' 
                      labelProps={{
                        className: "hidden",
                      }}
                      >
                        <Option value=''>Select an option</Option>
                        <Option value='cta_url'>Link</Option>
                        <Option value='button'>Button</Option>
                        <Option value='list'>List</Option>
                      </Select>   
                    </div>
                    {type === 'cta_url' && (
                      <>
                        <div className=''>
                          <p>
                            Body
                          </p>
                          <Textarea className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                          labelProps={{
                            className: "hidden",
                          }}
                          containerProps={{ className: "min-w-[100px]" }}
                          value={bodyText}
                          onChange={(e) => setBodyText(e.target.value)}
                          />
                          <p className='text-xs flex items-center gap-2 '>
                            <IoMdInformationCircle/>
                            Maximum 1024 Character ({bodyText.length}/1024)
                          </p>
                        </div>
                        <div className=''>
                          <p>
                            Footer
                          </p>
                          <InputCustom value={footerText} maxLength={60} onChange={(e) => setFooterText(e.target.value)}/>
                          <p className='text-xs flex items-center gap-2 mt-1'>
                            <IoMdInformationCircle/>
                            Maximum 60 Character ({footerText.length}/60)
                          </p>
                        </div>
                        <div className='flex md:flex-row flex-col justify-between items-start gap-5 w-full'>
                          <div className='md:w-[50%] w-full'>
                            <p>
                              Display Text
                            </p>
                            <InputCustom maxLength={20} value={displayText} onChange={(e) => setDisplayText(e.target.value)}/>
                            <p className='text-xs flex items-center gap-2 mt-1'>
                              <IoMdInformationCircle/>
                              Maximum 20 Character ({displayText.length}/20)
                            </p>
                          </div>
                          <div className='md:w-[50%] w-full'>
                            <p>
                              Url
                            </p>
                            <InputCustom value={url} onChange={(e) => setUrl(e.target.value)}/>
                          </div>
                        </div>
                      </>
                    )}
                    {type === 'button' && (
                      <>
                        <div className=''>
                          <p>
                            Body
                          </p>
                          <Textarea className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                          labelProps={{
                            className: "hidden",
                          }}
                          containerProps={{ className: "min-w-[100px]" }}
                          value={bodyText}
                          onChange={(e) => setBodyText(e.target.value)}
                          />
                          <p className='text-xs flex items-center gap-2 '>
                            <IoMdInformationCircle/>
                            Maximum 1024 Character ({bodyText.length}/1024)
                          </p>
                        </div>
                        <div className='w-full min-h-[150px] rounded-lg bg-gray-50 p-3'>
                          <div className='flex justify-between items-center'>
                            <p className='font-bold'>
                              Add Variable
                            </p>
                            <Button className='w-10 h-10 p-2' disabled={paramButtons.length >= 2} onClick={handleAddParamsButton}>
                              <IoAddCircleOutline className='text-2xl'/>
                            </Button>
                          </div>
                          <div className='space-y-5 mt-5'>
                            {paramButtons && paramButtons.map((p, index) =>{
                              return(
                                <div key={index} className='flex justify-between w-full items-center gap-5'>
                                  <InputCustom value={p.reply?.title}  onChange={(e) => handleParamsButtonInputChange(index, e.target.value)}/>
                                  <Button className='h-10 w-16 flex justify-center items-center hover:bg-red-500' onClick={() => handleRemoveParamsButton(index)}>
                                    <IoRemoveCircleOutline  className='text-2xl'/>
                                  </Button>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </>
                    )}
                    {type === 'list' && (
                      <>
                        <div className=''>
                          <p>
                            Header
                          </p>
                          <InputCustom value={headerText} maxLength={60} onChange={(e) => setHeaderText(e.target.value)}/>
                          <p className='text-xs flex items-center gap-2 mt-1'>
                            <IoMdInformationCircle/>
                            Maximum 60 Character ({headerText.length}/60)
                          </p>
                        </div>
                        <div className=''>
                          <p>
                            Body
                          </p>
                          <Textarea className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                          labelProps={{
                            className: "hidden",
                          }}
                          containerProps={{ className: "min-w-[100px]" }}
                          value={bodyText}
                          onChange={(e) => setBodyText(e.target.value)}
                          />
                          <p className='text-xs flex items-center gap-2 '>
                            <IoMdInformationCircle/>
                            Maximum 1024 Character ({bodyText.length}/1024)
                          </p>
                        </div>
                        <div className=''>
                          <p>
                            Footer
                          </p>
                          <InputCustom value={footerText} maxLength={60} onChange={(e) => setFooterText(e.target.value)}/>
                          <p className='text-xs flex items-center gap-2 mt-1'>
                            <IoMdInformationCircle/>
                            Maximum 60 Character ({footerText.length}/60)
                          </p>
                        </div>
                        <div className=''>
                            <p>
                              Display Text
                            </p>
                            <InputCustom maxLength={20} value={displayText} onChange={(e) => setDisplayText(e.target.value)}/>
                            <p className='text-xs flex items-center gap-2 mt-1'>
                              <IoMdInformationCircle/>
                              Maximum 20 Character ({displayText.length}/20)
                            </p>
                          </div>
                        <div className='w-full min-h-[150px] rounded-lg bg-gray-50 p-3'>
                          <div className='flex justify-between items-center'>
                            <p className='font-bold'>
                              Add Sections
                            </p>
                            <div className='flex gap-5'>
                              <Button className='w-10 h-10 p-2' onClick={addSection}>
                                <IoAddCircleOutline className='text-2xl'/>
                              </Button>
                            </div>
                          </div>
                          <div className='space-y-5 mt-5'>
                            {data.sections && data.sections.map((section, sectionIndex) =>{
                              return(
                                <div key={sectionIndex} className='w-full rounded-lg bg-gray-100 p-3 gap-2'>
                                  <div className='w-full space-y-3'>
                                    <div className='w-full flex justify-between items-center'>
                                      <p>
                                        Section Title
                                      </p>
                                      <Button className='h-8 w-16 flex justify-center items-center hover:bg-red-500' onClick={() => removeSection(sectionIndex)}>
                                        <IoRemoveCircleOutline  className='text-xl'/>
                                      </Button>
                                    </div>
                                    <div className='flex justify-between items-end md:flex-row flex-col gap-2'>
                                      <InputCustom value={section.title} onChange={(event) => handleSectionTitleChange(event, sectionIndex)}/>
                                      <Button className='flex justify-center gap-2 items-center p-2 h-10 w-40' onClick={() => addRow(sectionIndex)}>
                                        <IoAddCircleOutline className='text-2xl'/>
                                        Add Rows
                                      </Button>
                                    </div>
                                  </div>
                                  {section.rows && section.rows.map((row, rowIndex) => (
                                    <div key={row.id} className='mt-3 space-y-3'>
                                      <div className='w-full flex justify-between items-center'>
                                        <p className='font-semibold'>Row {rowIndex+1}</p>
                                        <Button className='h-8 w-16 flex justify-center items-center hover:bg-red-500' onClick={() => removeRow(rowIndex)}>
                                          <IoRemoveCircleOutline  className='text-xl'/>
                                        </Button>
                                      </div>
                                      <div>
                                        <hr className='w-full text-gray-600 rounded-lg h-0.5'/>
                                        <div>
                                          <p>
                                            Row Title
                                          </p>
                                          <InputCustom value={row.title} maxLength={25} onChange={(event) => handleRowFieldChange(event, sectionIndex, rowIndex, 'title')}/>
                                          <p className='text-xs flex items-center gap-2 mt-1'>
                                            <IoMdInformationCircle/>
                                            Maximum 25 Character ({row.title.length}/25)
                                          </p>
                                        </div>
                                        <div>
                                          <p>
                                            Row Description
                                          </p>
                                          <InputCustom value={row.description} maxLength={60} onChange={(event) => handleRowFieldChange(event, sectionIndex, rowIndex, 'description')}/>
                                          <p className='text-xs flex items-center gap-2 mt-1'>
                                            <IoMdInformationCircle/>
                                            Maximum 60 Character ({row.description.length}/60)
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className='md:w-[40%] w-full mt-5'>
                    <MessagePreview size={'w-full'} headerPreview={headerText} bodyPreview={bodyText}
                    footerPreview={footerText} action={param.response.action}
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Dialog>
        <div className='absolute md:right-5 z-10 bottom-5 p-5 md:w-[21%] w-full'>
          <Alert open={alert} color="red">
            <p className='font-bold'>
              Error
            </p>
            <p>
              Message : {alertMessage}
            </p>
        </Alert>
        </div>
    </div>
  )
}

export default AutoReplySetting