import React, { useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { IconButton, Input } from '@material-tailwind/react';
import { IoCloseCircle } from "react-icons/io5";
import { FaCircleInfo } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import avatar from '../img/default_avatar.svg'

const InputCustom = ({value, disabled, onChange, label, icon}) =>{
  return(
    <Input
      className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
      labelProps={{
        className: "hidden",
      }}
      containerProps={{ className: "min-w-[100px]" }}
      disabled={disabled}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      label={label}
      icon={icon}
    />
  )
}

const UserProfile = ({ username, handleInfo, q, setQ}) => {

  const{setSelectedChat, setActiveListChat, screenSize} = useStateContext()
  const[openSearch, setOpenSearch] = useState(false)
  const handleBackToChatList = () => {
    setSelectedChat(null);
    setActiveListChat(true);
  };

  const handleOpenSearch = () => setOpenSearch(!openSearch)
  
  return (
    <div className="flex items-center bg-white lg:p-5 p-3 border-gray-200 h-20 shadow-md border-b rounded-b-xl">
      {openSearch ?
      <div className='w-full flex gap-1'>
        <InputCustom icon={<FaSearch/>} value={q} onChange={setQ}/>
        <IconButton className='block text-2xl' variant='text' onClick={handleOpenSearch}>
          <IoCloseCircle/>
        </IconButton>
      </div>
      :
      <>
      <div className='w-[25%] flex justify-start'>
        {/* <div className='min-h-8 min-w-8 mr-5 bg-gray-500 p-3 text-white rounded-full'>
          <IoPersonOutline className='text-lg '/>
        </div> */}
        <img alt='' src={avatar} className='h-12 rounded-full' />
      </div>
      {screenSize < 960 ?
      <div className='w-full flex justify-center items-center h-full rounded-lg hover:bg-gray-100 lg:hover:bg-inherit' onClick={handleInfo}>
        <div className="text-lg font-semibold text-center">{username}</div>
      </div>
      :
      <div className='w-full flex justify-center items-center h-full rounded-lg hover:bg-gray-100 lg:hover:bg-inherit'>
        <div className="text-lg font-semibold text-center">{username}</div>
      </div>
      }
      <div className='flex items-center w-[25%] justify-end'>
        <IconButton className='block text-xl' variant='text' onClick={handleOpenSearch}>
          <FaSearch/>
        </IconButton>
        <IconButton className='hidden lg:block text-xl' variant='text' onClick={handleInfo}>
          <FaCircleInfo/>
        </IconButton>
        <IconButton className='lg:hidden block text-2xl' variant='text' onClick={handleBackToChatList}>
          <IoCloseCircle/>
        </IconButton>
      </div>
      </>
      }
    </div>
  );
}

export default UserProfile;