import { Button, Card, CardBody, Input, Select, Option, Alert } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { LuInfo } from "react-icons/lu";
import { useStateContext } from '../contexts/ContextProvider';
import API from '../API/API';
import AutoReplySetting from '../components/AutoReplySetting';
import { AgentList } from '../components'
import { FaRobot } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";
import useDocumentTitle from '../useDocumentTitle';

const InputCustom = ({value, disabled, onChange, label}) =>{
  return(
    <Input
      className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
      labelProps={{
        className: "hidden",
      }}
      containerProps={{ className: "min-w-[100px]" }}
      disabled={disabled}
      value={value}
      onChange={onChange}
      label={label}
    />
  )
}

const AccountManagement = ({user}) =>{
  const {setEditUser, editUser} = useStateContext()
  const username = user.username
  const [division, setDivision] = useState(user.division)
  const [email, setEmail] = useState(user.email)
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber)
  const [phoneId, setPhoneId] = useState(user.phoneId)
  //=====================ERROR HANDLING===================
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const handleError = (message) =>{
    setAlert(true)
    setAlertMessage(message)
  }

  if(alert){
    setTimeout(() => {
    setAlert(false)
    }, 5000);
  }
  const handleEdit = () =>{
    const data = {
      username: username,
      division: division,
      email: email,
      phoneNumber: phoneNumber,
      phoneId: phoneId
    }
    try {
      API.put('/api/auth/edit', data).then((response) => {
        if(response.status === 200){
          setEditUser([...editUser,1])
        }
      })
    } catch (error) {
      if(error.message !== 'Network Error'){
        handleError(error.response.data?.message)
      }else{
        handleError('Network Error')
      }
    }

  }
  return(
    <div className='space-y-10'>
      <div className='space-y-5'>
        <div className='flex justify-between items-center md:flex-row flex-col gap-2'>
          <p className='text-2xl font-bold'>
            Current User Information
          </p>
          <Button className='md:w-[20%] w-full' disabled={division === user.division && email === user.email && phoneNumber === user.phoneNumber && phoneId === user.phoneId} onClick={handleEdit}>
            Save
          </Button>
        </div>
        <div className='space-y-1'>
          <p className='text-sm'>
            Agent Username
          </p>
          <InputCustom value={username} disabled={true}/>      
        </div>
        <div className='space-y-1'>
          <p className='text-sm'>
            Agent Division
          </p>
          <Select value={division} onChange={setDivision} className='!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900' 
          labelProps={{
            className: "hidden",
          }}
          >
            <Option value='hrd'>HRD</Option>
            <Option value='marketing'>Marketing</Option>
            <Option value='cs'>Customer Service</Option>
          </Select>    
        </div>
        <div className='space-y-1'>
          <p className='text-sm'>
            Agent Email
          </p>
          <InputCustom value={email} disabled={false} onChange={(e) => setEmail(e.target.value)}/>      
        </div>
        <div className='space-y-1'>
          <p className='text-sm'>
            Phone Number
          </p>
          <InputCustom value={phoneNumber} disabled={false} onChange={(e) => setPhoneNumber(e.target.value)}/>      
        </div>
        <div className='space-y-1'>
          <p className='text-sm'>
            Phone Number ID
          </p>
          <InputCustom value={phoneId} disabled={false} onChange={(e) => setPhoneId(e.target.value)}/>      
        </div>
      </div>
      {user.roles !== 'agent' && (
        <div>
          <AgentList/>
        </div>
      )}
      <div className='absolute md:right-5 z-10 bottom-5 p-5 md:w-[21%] w-full'>
        <Alert open={alert} color="red">
          <p className='font-bold'>
            Error
          </p>
          <p>
            Message : {alertMessage}
          </p>
        </Alert>
      </div>
    </div>
  )
}

const AppInformation = ({socket}) =>{
  const [appInfo, setAppInfo] = useState({})
  const [bearer, setBearer] = useState('')
  const [cloudUrl, setCloudUrl] = useState('')
  const [cloudVer, setCloudVer] = useState('')
  const [apiUrl, setApiUrl] = useState('')

  //=====================ERROR HANDLING===================
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const handleError = (message) =>{
    setAlert(true)
    setAlertMessage(message)
  }

  if(alert){
    setTimeout(() => {
    setAlert(false)
    }, 5000);
  }
  
  useEffect(() => {
    const getSetting = async() => {
      try {
        const response = await API.get('/api/auth/settings')
          if(response.status === 200){
            setAppInfo(response.data);
            setBearer(response.data.token)
            setCloudUrl(response.data.urlApi)
            setCloudVer(response.data.version)
            setApiUrl(response.data.url)
          }
      } catch (error) {
        if(error.message !== 'Network Error'){
          handleError(error.response.data?.message)
        }else{
          handleError('Network Error')
        }
      }
    }
    getSetting()
  }, []);

  let data = {
    version: cloudVer,
    urlApi: cloudUrl,
    url: apiUrl,
    token: bearer
  }
  
  const editAppInfo = () =>{
    try {
      const response = API.put('/api/auth/settings', data)
      if(response.status === 200){
        setAppInfo(response.data)
      }
    } catch (error) {
      if(error.message !== 'Network Error'){
        handleError(error.response.data?.message)
      }else{
        handleError('Network Error')
      }
    }
  }

  return(
    <div className='space-y-5'>
      <div className='w-full flex md:flex-row flex-col gap-2 justify-between'>
        <p className='text-2xl font-bold'>
          App Information
        </p>
        <Button disabled={
          bearer === appInfo.token && cloudUrl === appInfo.urlApi && cloudVer === appInfo.version && apiUrl === appInfo.url
        } onClick={editAppInfo}>
          Save
        </Button>
      </div>
      <div className='space-y-1'>
        <div className='flex justify-between'>
          <p className='text-sm'>
            App Secret
          </p>
          <p className='text-sm hover:underline text-blue-gray-800 hover:text-blue-gray-400 cursor-pointer' onClick={() => {navigator.clipboard.writeText(appInfo.secret)}}>
            Copy
          </p>
        </div>
        <InputCustom value={appInfo.secret} disabled={true} label={'App Secret'}/>      
      </div>
      <div className='space-y-1'>
        <p className='text-sm'>
          Bearer
        </p>
        <InputCustom value={bearer} disabled={false} label={'Bearer'}
        onChange={e => setBearer(e.target.value)}/>
      </div>
      <div className='space-y-1'>
        <div className='flex justify-between'>
          <p className='text-sm'>
            Webhook URL
          </p>
          <p className='text-sm hover:underline text-blue-gray-800 hover:text-blue-gray-400 cursor-pointer' onClick={() => {navigator.clipboard.writeText(appInfo.url+'wa/webhook')}}>
            Copy
          </p>
        </div>
        <InputCustom value={`${appInfo.url}wa/webhook`} disabled={true} label={'Webhook URL'}/>
      </div>
      <div className='space-y-1'>
        <p className='text-sm'>
          Meta Cloud API URL
        </p>
        <InputCustom value={cloudUrl} disabled={false} label={'Meta URL'}
        onChange={e => setCloudUrl(e.target.value)}/>
      </div>
      <div className='space-y-1'>
        <p className='text-sm'>
          Meta Cloud API Version
        </p>
        <InputCustom value={cloudVer} disabled={false} label={'Meta URL'}
        onChange={e => setCloudVer(e.target.value)}/>
      </div>
      <div className='space-y-1'>
        <p className='text-sm'>
          API URL
        </p>
        <InputCustom value={apiUrl} disabled={false} label={'API URL'}
        onChange={e => setApiUrl(e.target.value)}/>     
      </div>
      <div className='absolute md:right-5 z-10 bottom-5 p-5 md:w-[21%] w-full'>
        <Alert open={alert} color="red">
          <p className='font-bold'>
            Error
          </p>
          <p>
            Message : {alertMessage}
          </p>
        </Alert>
      </div>
    </div>
  )
}


const Settings = ({socket}) => {
  useDocumentTitle('Settings - ChatApp')
  const {currentUser} = useStateContext()
  const [accMgtMenu,setAccMgtMenu] = useState(false)
  const [appInfoMenu,setAppInfoMenu] = useState(true)
  const [autoReply,setAutoReply] = useState(false)

  const handleOpenSubMenu = (value) =>{
    if(value === 'accMgt'){
      setAppInfoMenu(false)
      setAccMgtMenu(true)
      setAutoReply(false)
    }
    if(value === 'appInfo'){
      setAppInfoMenu(true)
      setAccMgtMenu(false)
      setAutoReply(false)
    }
    if(value === 'autoRep'){
      setAppInfoMenu(false)
      setAccMgtMenu(false)
      setAutoReply(true)
    }
  }
  return (
    <div className='mx-5 max-h-full'>
        <div className="flex gap-5 w-full h-full md:flex-row flex-col">
            <div className="md:w-[30%] w-full h-full overflow-y-scroll overscroll-contain bg-gray-50 p-5 rounded-lg">
              <p className='text-2xl font-bold mb-5'>
                Settings
              </p>
              <p className='text-sm uppercase text-gray-500 mb-5'>
                Account
              </p>
              <div className='space-y-5'>
                <Button variant='outlined' className='h-14 rounded-full flex justify-start gap-6 items-center w-full normal-case font-normal' onClick={() => {handleOpenSubMenu('appInfo')}}>
                  <LuInfo className='text-lg'/>
                  <p className='text-base'>
                    App Information
                  </p>
                </Button>
                <Button variant='outlined' className='h-14 rounded-full flex justify-start gap-6 items-center w-full normal-case font-normal' onClick={() => {handleOpenSubMenu('accMgt')}}>
                  <RiCustomerService2Fill className='text-lg'/>
                  <p className='text-base'>
                    User Management
                  </p>
                </Button>
                <Button variant='outlined' className='h-14 rounded-full flex justify-start gap-6 items-center w-full normal-case font-normal' onClick={() => {handleOpenSubMenu('autoRep')}}>
                  <FaRobot className='text-lg'/>
                  <p className='text-base'>
                    AutoReply
                  </p>
                </Button>
              </div>
            </div>
            <div className="md:w-[70%] w-full h-full sticky top-16 overflow-y-scroll overscroll-contain bg-gray-100 p-5 rounded-lg">
              <Card>
                <CardBody>
                  {accMgtMenu && (
                    <AccountManagement user={currentUser}/>
                  )}
                  {appInfoMenu && (
                    <AppInformation socket={socket}/>
                  )}
                  {autoReply && (
                    <AutoReplySetting socket={socket}/>
                  )}
                </CardBody>
              </Card>
            </div>
        </div>
    </div>
  )
}

export default Settings