import { Alert, Button, Input, Textarea } from '@material-tailwind/react'
import React, { useState } from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { useStateContext } from '../contexts/ContextProvider'

const InputCustom = ({value, disabled, onChange, label, type, error}) =>{
  switch(type){
    case 'input':
      return(
        <Input
          className={`!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ${error ? 'ring-red-400' : "ring-transparent"} placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900  ${error ? 'focus:ring-red-400' : "focus:ring-gray-900/10"}`}
          labelProps={{
            className: "hidden",
          }}
          containerProps={{ className: "min-w-[100px]" }}
          disabled={disabled}
          value={value}
          onChange={onChange}
          label={label}
        />
      )
    case 'textarea':
      return(
        <Textarea
        className={`!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ${error ? 'ring-red-400' : "ring-transparent"} placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 ${error ? 'focus:ring-red-400' : "focus:ring-gray-900/10"}`}
        labelProps={{
            className: "hidden",
        }}
        value={value}
        maxLength={4000}
        onChange={onChange}
        />
      )
    default:
      break;
  }
}

const CreateShortcut = ({socket, handler, mode, shortcut}) => {
  const [keyword, setKeyword] = useState(shortcut ? shortcut.keyword : '')
  const [content, setContent] = useState(shortcut ? shortcut.content : '')
  const {currentUser} = useStateContext()

  //=====================ERROR HANDLING===================
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const handleError = (message) =>{
    setAlert(true)
    setAlertMessage(message)
  }

  if(alert){
    setTimeout(() => {
    setAlert(false)
    }, 5000);
  }

  const editTemplateShortcut = () =>{
    socket.emit('editTemplateShortcut', {
      keyword: keyword,
      content: content,
    }, (response) => {
      if(response.status === 'success'){
        handler()
      }else{
        handleError(response.message)
      }
    })
  }

  const createTemplateShortcut = () =>{
    socket.emit('createTemplateShortcut', {
      keyword: keyword,
      content: content,
      division: currentUser.division
    }, (response) => {
      if(response.status === 'success'){
        handler()
      }else{
        handleError(response.message)
      }
    })
  }

  return (
    <div className='w-full h-full text-gray-800'>
      <div className='p-5 space-y-5'>
        <p className='text-2xl font-bold'>
          Create New Shortcut
        </p>
        <div className='space-y-5'>
          <div>
            <p className='text-sm font-semibold'>
              Keyword
            </p>
            <InputCustom type={'input'} onChange={(e) => setKeyword(e.target.value)} value={keyword} error={keyword.length>0 && keyword.substring(0,1) !== '/'}/>
            <div className='text-xs mt-2 p-1 flex gap-2 items-center'>
              <FaInfoCircle/>
              <p>
                Keyword must start with ' / '
              </p>
            </div>
          </div>
          <div>
            <p className='text-sm font-semibold'>
              Content
            </p>
            <InputCustom type={'textarea'} onChange={(e) => setContent(e.target.value)} value={content} error={content.length > 4000}/>
            <div className='text-xs mt-2 p-1 flex gap-2 items-center'>
              <FaInfoCircle/>
              <p>
                Maximum 4000 Character ({content.length}/4000)
              </p>
            </div>
          </div>
        </div>
        <div className='w-full flex items-center justify-center '>
          <Button onClick={mode === 'edit' ? editTemplateShortcut : createTemplateShortcut}>
            {mode === 'edit' ? 'Edit' : 'Create'}
          </Button>
        </div>
      </div>
      <div className='absolute md:right-5 z-10 bottom-5 p-5 md:w-[21%] w-full'>
        <Alert open={alert} color="red">
          <p className='font-bold'>
            Error
          </p>
          <p>
            Message : {alertMessage}
          </p>
        </Alert>
      </div>
    </div>
  )
}

export default CreateShortcut