import { Alert, Button, Card, CardBody, CardFooter, CardHeader, Dialog, Input, Typography } from '@material-tailwind/react'
import React, { useEffect, useMemo, useState } from 'react'
import { PiMagnifyingGlassBold } from "react-icons/pi";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { IoMdInformationCircle } from "react-icons/io";
import useDocumentTitle from '../useDocumentTitle';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
//=======================TABLE COLUMNS=====================
const columns = [
    {
        id: 'name', 
        label: 'Customer Name',
        disablePadding: true,
        minWidth: 200
    },
    {
        id: 'numberPhone', 
        label: 'Phone Number',
        disablePadding: true,
        minWidth: 150
    },
    {
        id: 'createdAt', 
        label: 'Created At',
        disablePadding: true,
        minWidth: 200,
        format: (value) => new Date(value).toLocaleString('en-GB')
    },
]

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={'left'}
              sortDirection={orderBy === column.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
              >
                <p className='font-bold rounded-lg'>
                    {column.label}
                </p>
                {orderBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

const Customers = ({socket}) => {
    useDocumentTitle('Customer - ChatApp')
    const [customerList, setCustomerList] = useState([])
    //========================PAGINATION===================
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    //========================TABLE SORTING===================
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('createdAt');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - customerList.length) : 0;

    const visibleRows = useMemo(
        () =>
            stableSort(customerList, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, customerList],
    );

    //=========================GET CUSTOMER LIST==============
    useEffect(() => {
        socket.emit("getCustomerList", {},(response) =>{
            if(response){
                setCustomerList(response.data)
            }
        })
    }, [socket]);

    //=======================LIVE SEARCH=======================
    const [q, setQ] = useState("");
    const filteredRows = visibleRows.filter(item =>
      item.numberPhone.includes(q) || item.name.toLowerCase().includes(q.toLowerCase())
    );
    const displayedRows = q ? filteredRows : visibleRows;

    //=====================ERROR HANDLING===================
    const [alert, setAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')

    const handleError = (message) =>{
      setAlert(true)
      setAlertMessage(message)
    }
    
    if(alert){
      setTimeout(() => {
      setAlert(false)
      }, 5000);
    }

    //======================DIALOG ADD CUSTOMER===============
    const [open, setOpen] = useState(false);
    const [newCustomerName, setNewCustomerName] = useState('');
    const [newCustomerPhone, setNewCustomerPhone] = useState('');
    const handleOpen = () => setOpen((cur) => !cur);
    const handleCreateCustomer = () =>{
      const param = {
        name: newCustomerName,
        numberPhone: newCustomerPhone
      }
      try {
          socket.emit("addNewCustomer", param,(response) =>{
              if(response){
                  setOpen(false)
              }
          })
      } catch (error) {
        handleError(error)
      }
    }


  return (
    <div className='mx-5 my-5'>
      <div className='flex justify-between'>
          <p className='text-3xl font-bold'>
              Customers
          </p>
      </div>
      <div className='mt-5'>
          <Card className='h-full'>
              <CardHeader floated={false} shadow={false} className="rounded-none flex md:flex-row flex-col justify-between mx-10 items-center">
                <div className="my-4 w-full md:w-72">
                    <Input
                        label="Search Customers"
                        icon={<PiMagnifyingGlassBold className="h-5 w-5" />}
                        value={q}
                        onChange={e => setQ(e.target.value)}
                    />
                </div>
                <Button className='md:w-[20%] w-full' onClick={handleOpen}>
                    Add New Contact
                </Button>
              </CardHeader>
              <CardBody>
                  <TableContainer>
                      <Table stickyHeader aria-label="sticky table">
                          <EnhancedTableHead
                              order={order}
                              orderBy={orderBy}
                              onRequestSort={handleRequestSort}
                              rowCount={customerList.length}
                          />
                          <TableBody>
                              {displayedRows.map((row, index) => {
                                  return (
                                  <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row.id}
                                      sx={{ cursor: 'pointer' }}
                                  >
                                      <TableCell>{row.name}</TableCell>
                                      <TableCell>{row.numberPhone}</TableCell>
                                      <TableCell>{new Date(row.createdAt).toLocaleString('en-GB')}</TableCell>
                                  </TableRow>
                                  );
                              })}
                              {emptyRows > 0 && (
                                  <TableRow
                                  style={{
                                      height: (53) * emptyRows,
                                  }}
                                  >
                                  <TableCell colSpan={6} />
                                  </TableRow>
                              )}
                              </TableBody>
                      </Table>
                  </TableContainer>
                  <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={customerList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                  />
              </CardBody>
          </Card>
      </div>
      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto w-full max-w-[24rem]">
          <CardBody className="flex flex-col gap-4">
            <Typography variant="h4" color="blue-gray">
              Add New Customer
            </Typography>
            <Typography
              className="mb-3 font-normal"
              variant="paragraph"
              color="gray"
            >
              Enter the customer details.
            </Typography>

            <Typography className="-mb-2" variant="h6">
              Name
            </Typography>
            <Input label="Name" size="lg" value={newCustomerName} onChange={(e) => setNewCustomerName(e.target.value)}/>

            <Typography className="-mb-2" variant="h6">
              Phone Number
            </Typography>
            <Input label="Phone Number" size="lg" type='number' value={newCustomerPhone}  onChange={(e) => setNewCustomerPhone(e.target.value)} error={newCustomerPhone && (newCustomerPhone.substring(0,2) !== '62' || newCustomerPhone < 0)} min={0}/>
            <Typography
                variant="small"
                className="-mt-2 flex items-center gap-1 font-normal text-gray-700 opacity-80"
            >
                <IoMdInformationCircle/>
                Replace '0' with 62
            </Typography>
          </CardBody>
          <CardFooter className="pt-0">
            <Button variant="gradient" fullWidth onClick={handleCreateCustomer} disabled={!newCustomerName || !newCustomerPhone || (newCustomerPhone.substring(0,2) !== '62' || newCustomerPhone < 0) }>
              Create
            </Button>
          </CardFooter>
        </Card>
      </Dialog>
      <div className='absolute md:right-5 z-10 bottom-5 p-5 md:w-[21%] w-full'>
        <Alert open={alert} color="red">
          <p className='font-bold'>
            Error
          </p>
          <p>
            Message : {alertMessage}
          </p>
        </Alert>
      </div>
    </div>
  )
}

export default Customers