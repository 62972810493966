// ContextProvider.js

import React, { createContext, useContext, useEffect, useState } from 'react';

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const [activeListChat, setActiveListChat] = useState(true);
  const [activeSidebar, setActiveSidebar] = useState(false);
  const [activeNavbar, setActiveNavbar] = useState(false);
  const [screenSize, setScreenSize] = useState(undefined);
  const [selectedChat, setSelectedChat] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loggedIn,setLoggedIn] = useState(false);
  const [messageSent,setMessageSent] = useState([]);
  const [editUser,setEditUser] = useState([]);
  const [roomId,setRoomId] = useState('');

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (screenSize >= 960) {
      setActiveNavbar(true);
      setActiveSidebar(false);
      setActiveListChat(true);
    } else {
      setActiveNavbar(false);
      setActiveSidebar(true);
      setActiveListChat(true);
    }
  }, [screenSize]);

  return (
    <StateContext.Provider
      value={{
        selectedChat,
        setSelectedChat,
        activeListChat,
        setActiveListChat,
        activeSidebar,
        setActiveSidebar,
        screenSize,
        setScreenSize,
        activeNavbar,
        setActiveNavbar,
        currentUser,
        setCurrentUser,
        loggedIn,
        setLoggedIn,
        messageSent,
        setMessageSent,
        editUser,
        setEditUser,
        roomId,
        setRoomId,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
