import { Button, IconButton, Dialog, DialogBody, DialogHeader, Select, Option, Input, Popover, PopoverHandler, PopoverContent} from '@material-tailwind/react';
import React, { useEffect, useRef, useState } from 'react'
import { BiCheckDouble } from "react-icons/bi";
import UserProfile from './UserProfile';
import AutoResizeTextarea from './TextArea';
import { useStateContext } from '../contexts/ContextProvider';
import { Chip } from "@material-tailwind/react";
import Loading from './Loading';
import { FaDownload, FaMicrophone, FaPlay, FaVideo,  } from 'react-icons/fa6';
import { FaCamera, FaFileAlt, FaReply } from 'react-icons/fa';
import { IoCloseSharp, IoDocumentAttachOutline, IoImageOutline, IoSend, IoWarningOutline, IoCloseCircle, IoDocument } from 'react-icons/io5';
import MessagePreview from './MessagePreview';
import { pdfjs, Document, Page } from 'react-pdf';
import { GrLinkNext, GrLinkPrevious  } from "react-icons/gr";
import API from '../API/API'
import { TfiMenuAlt } from "react-icons/tfi";
import { MdOutlineReply } from "react-icons/md";
import { FaExternalLinkAlt, FaImage } from "react-icons/fa";
import imageThumbnail from '../img/image-thumbnail.png'
import videoThumbnail from '../img/video-thumbnail.png'
import documentThumbnail from '../img/document-thumbnail.png'
import { MdOutlineCloudUpload } from "react-icons/md";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const InteractiveMessage = ({interactive, showInfo }) =>{
  const action = interactive.interactive.action
  const [showAction, setShowAction] = useState(false)
  const handleShowAction = () => setShowAction(!showAction)
  let header
  let body
  let footer
  if(interactive.interactive.header){
    header = interactive.interactive.header.text
  }
  if(interactive.interactive.body){
    body = interactive.interactive.body.text
  }
  if(interactive.interactive.footer){
    footer = interactive.interactive.footer.text
  }
  return(
      <>
        <div className='font-bold mb-1'>
            {header}
        </div>
        <p className={`font-normal mx-0 mb-1`}>
            {body}
        </p>
        <p className={`font-thin text-sm opacity-80 italic mb-1 mx-0`}>
            {footer}
        </p>
        {Array.isArray(action.buttons) && (action.buttons.map((button, index) => {
            return(
            <div className='w-full flex flex-col justify-center' key={index}>
                <hr className='w-full opacity-50'/>
                <div className='flex justify-center items-center w-full text-light-blue-300 text-sm hover:text-light-blue-100 cursor-pointer select-none p-2'>
                    <MdOutlineReply className='text-xl'/>
                    <p>{button.reply?.title}</p>
                </div>
            </div>
            )
        }))}
        {action.parameters ? (
            <div className='w-full flex flex-col justify-center'>
                <hr className='w-full opacity-50'/>
                <div className='text-light-blue-300 hover:text-light-blue-100 cursor-pointer select-none p-2'>
                    <a href={action.parameters.url} target="_blank" rel="noopener noreferrer" className='flex gap-2 justify-center items-center w-full text-sm'>
                        <FaExternalLinkAlt className='text-base'/>
                        <p>{action.parameters.display_text}</p>
                    </a>
                </div>
            </div>
        ): !Array.isArray(action.buttons) ? (
            <div className='w-full flex flex-col justify-center gap-1'>
                <hr className='text-white h-0.5 w-full'/>
                <div className='w-full flex justify-center items-center hover:bg-white hover:bg-opacity-10'>
                    <div className='w-full flex gap-1 items-center justify-center text-light-blue-300 text-sm hover:text-light-blue-100 cursor-pointer select-none ' onClick={handleShowAction}>
                        <TfiMenuAlt className=''/>
                        <p className=''>
                            {action.button}
                        </p>
                    </div>
                </div>
                {showAction && (
                <div className={`${showInfo ? 'lg:w-[60%]' : 'lg:w-[80%]'} transition-all absolute break-words rounded-xl h-full w-full left-0 lg:left-[20%] bottom-20 select-none`}>
                  <div className='flex rounded-lg h-full bg-black bg-opacity-10 w-full items-end '>
                    <div className='rounded-lg h-[60%] bg-gray-800 w-full p-2 overflow-y-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]'>
                      <div className='flex w-full'>
                        <div className='flex w-full justify-center'>
                            <p className='ml-5'>
                                {action.button}
                            </p>
                        </div>
                        <div className='flex items-center'>
                            <IoCloseCircle className='opacity-70 hover:opacity-100 text-xl cursor-pointer' onClick={handleShowAction}/>
                        </div>
                      </div>
                      {action.sections && action.sections.map((section, index) => {
                        return(
                        <div className='mt-2 my-5' key={index}>
                            <p className='text-xs font-semibold opacity-80 mb-1'>
                                {section.title}
                            </p>
                            <hr className='w-full opacity-50'/>
                            {section.rows.map((row, index) =>{
                                return(
                                    <div key={row.id}>
                                        <div className='hover:bg-white hover:bg-opacity-10 py-2 cursor-pointer'>
                                            <p className='text-base'>
                                                {row.title}
                                            </p>
                                            <p className='text-xs opacity-50'>
                                                {row.description}
                                            </p>
                                        </div>
                                        <hr className='w-full opacity-50'/>
                                    </div>
                                )
                            })}
                        </div>
                        )
                      })}
                      <div className='flex justify-center items-center w-full'>
                        <p className='text-xs opacity-50 mb-2'>
                            Tap an item to select it
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                )}
            </div>
        ): ''}
      </>
  )
}
const MediaMessage = ({message, selectedChat}) => {
  const isCurrentUser = message?.fromId?.toString() !== selectedChat?.numberPhone;
  const [openImage, setOpenImage] = useState(false)
  const [zoomImage, setZoomImage] = useState(false)
  const [openVideo, setOpenVideo] = useState(false)
  let files
  if(message.files){
    files = JSON.parse(message.files)
    switch(files.type){
      case 'image':
        const handleOpenImage = () => setOpenImage(!openImage);
        const handleZoomImage = () => setZoomImage(!zoomImage);
        return(
          <>
            <div className="group relative">
              <div className='absolute h-full w-full flex items-center justify-center'>
                <p className='h-full w-full bg-black hover:cursor-pointer opacity-0 bg-opacity-30 hover:opacity-80 flex items-center justify-center rounded-lg z-50 transition-all' onClick={handleOpenImage}>
                </p>
              </div>
              <img src={files.url} alt='' className="rounded-lg object-cover h-80" onClick={handleOpenImage}/>
            </div>
            <Dialog size='xs' open={openImage} handler={handleOpenImage} className='bg-opacity-0 shadow-none w-auto max-h-screen'>
              <DialogBody className='flex justify-center w-auto'>
                  <img src={files.url} alt='' className={`rounded-lg max-h-[90vh] overflow-scroll ${zoomImage ? 'scale-[2.5] cursor-zoom-out' : 'cursor-zoom-in'}`} onClick={handleZoomImage}/>
              </DialogBody>
            </Dialog>
          </>
        )
      case 'video':
        const handleOpenVideo = () => setOpenVideo(!openVideo);
        return( 
          <>
          <div className="group relative">
            <div className='absolute h-full w-full flex items-center justify-center'>
              <div className='h-16 w-16 bg-black hover:cursor-pointer opacity-50 bg-opacity-50 hover:opacity-100 flex items-center justify-center rounded-full z-50 transition-all' onClick={handleOpenVideo}>
                <FaPlay className='ml-1 text-4xl'/>
              </div>
            </div>
            <video className="rounded-lg object-cover h-80" preload="metadata">
              <source src={files.url} type='video/mp4' />
            </video>
          </div>
          <Dialog size='xs' open={openVideo} handler={handleOpenVideo} className='bg-opacity-0 shadow-none w-auto max-h-screen'>
            <DialogBody className='flex justify-center w-auto'>
              <video className="rounded-lg object-cover max-h-[90vh]" controls>
                <source src={files.url} type='video/mp4' />
              </video>
            </DialogBody>
          </Dialog>
          </>
        )
      case 'document':
        const onButtonClick = () => {
          fetch("https://cors-anywhere.herokuapp.com/"+files.url).then((response) => { //===============PROXY SEMENTARA
              response.blob().then((blob) => {
                const fileURL =
                    window.URL.createObjectURL(blob);
                    
                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = files.fileName;
                alink.click();
              });
          });
        };
        return (
        <div className={`flex items-center rounded-xl ${isCurrentUser ? 'bg-[#6B98A8]' : 'bg-[#779c70]'} p-4 bg-opacity-100`}>
          <div className="me-2">
            <span className="flex items-center gap-2 text-sm font-medium text-white">
              <div className='h-8 w-8 flex items-center'>
                <FaFileAlt className='text-2xl' />
              </div>
              <p className='break-all hyphens-auto'>
              {files.fileName.length > 40 ? `${files.fileName?.substring(0,45)}...` : files.fileName}
              </p> 
            </span>
          </div>
          <div className="inline-flex items-center self-center">
            <IconButton color='white' className={`inline-flex items-center self-center rounded-lg bg-white text-center text-sm font-medium ${isCurrentUser ? 'text-[#6B98A8]' : 'text-[#779c70]'} hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-50`} onClick={onButtonClick} >
              <FaDownload />
            </IconButton>
          </div>
        </div>
        )
      case 'audio':
        return( 
        <audio controls>
          <source src={files.url} type='audio/ogg' />
        </audio>
        )
      case 'sticker':
        return(
        <div className="group relative">
            <img alt='' src={files.url} className="rounded-lg object-cover h-20" data-mode="video" />
        </div>
        )
      default:
      break;
    }
  }
}
const InputComponent = ({ label, onInputChange, disabled }) => {
  return (
      <Input
      required
      type="text"
      label={label}
      onChange={(e) => onInputChange(e.target.value)}
      className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
      labelProps={{
        className: "hidden",
      }}
      containerProps={{ className: "min-w-[100px]" }}
      disabled={disabled}
      />
  );
};

const ParameterSection = ({ title, content, params, onInputChange }) => {
  let shownParams
  if(content?.match(/({{\d+}})/gm)?.length){
      shownParams = params
  }
  return (
      <div className='mb-5'>
          <p className='font-semibold text-xl my-2'>
              {title}
          </p>
          <p className='my-2'>{content}</p>
          {shownParams && (
              <div>
              <p className='text-sm font-bold my-2'>
                  Parameter
              </p>
              <div>
                  {shownParams.map((p, index) => (
                  <div key={index} className='flex items-center justify-start gap-2 space-y-2'>
                      <p className='font-semibold w-10'>
                      {`{{${index + 1}}}`}
                      </p>
                      <InputComponent
                      label={p}
                      onInputChange={(value) => onInputChange(index, value)}
                      />
                  </div>
                  ))}
              </div>
              </div>
          )}
      </div>
  );
};

const PopoverShortcut = ({currentUser, newMessage, socket, setNewMessage}) => {
  const[shortcut, setShortcut] = useState([])
  useEffect(() => { 
    socket.emit('templateShortcut' , currentUser, (response) =>{
      if(response){
        setShortcut(response)
      }
    })
  }, [currentUser, socket]);

  const filteredShortcut = shortcut?.filter(msg =>
    msg.keyword.toLowerCase().includes(newMessage.substring(1).toLowerCase())
  );
  const displayedShortcut = newMessage.length > 1 ? filteredShortcut : shortcut;
  return(
    <div className='w-full max-h-[500px] overflow-y-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]'>
      {displayedShortcut.length > 0 ? displayedShortcut.map((data, index) => {
        return(
          <div className='w-full bg-white border-1 text-gray-600 rounded-lg border-blue-gray-400' onClick={() => setNewMessage(data.content)}>
            <div className='flex cursor-pointer hover:bg-gray-100 rounded p-5 min-10' key={index}>
              <div className='w-1/2'>
                <p className='font-bold'>
                  {data.keyword}
                </p>
              </div>
              <div className='w-1/2'>
                {data.content}
              </div>
            </div>
          </div>
        )
      })
    :
    <div className='w-full bg-white border-1 text-gray-600 rounded-lg border-blue-gray-400'>
      <div className='flex cursor-pointer hover:bg-gray-100 rounded p-5 min-10'>
        <p className='font-bold'>No template for keyword {newMessage}</p>
      </div>
    </div>
    }
    </div>
  )
}

const ChatRoom = ({ selectedChat, setSelectedChat, currentUser, socket, handleInfo, showInfo }) => {
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const chatContainerRef = useRef(null)
  const [disableChat, setDisableChat] = useState(true)
  const{messageSent,setMessageSent, screenSize} = useStateContext()
  const [openStartChat, setOpenStartChat] = useState(false)
  const [templateList, setTemplateList] = useState([])
  const [template, setTemplate] = useState(null)
  const id = useRef(selectedChat?.id)
  //=======================LIVE SEARCH=======================
  const [q, setQ] = useState("");
  const filteredRows = messages?.filter(msg =>
    msg.message?.toLowerCase().includes(q?.toLowerCase()) || msg.interactive?.toLowerCase().includes(q?.toLowerCase())
  );
  const displayedRows = q ? filteredRows : messages;
  //Function Date Label
  let lastDisplayedDate = null;
  const formatDateLabel = (date) => {
    const today = new Date();
    const messageDate = new Date(date);
    const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jum`at", "Sabtu"];
    const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "Desember"];
    const inDays = (d1, d2) => {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return parseInt((t2 - t1) / (24 * 3600 * 1000));
    }
    let diffDays = inDays(messageDate, today);
    if (diffDays === 0) {
        if (messageDate.getDate() === today.getDate()) {
            return 'Hari Ini';
        } else {
          return days[messageDate.getDay()];
        }
    } else if (diffDays >= 1 && diffDays <= 5) {
      return days[messageDate.getDay()]
    } else {
      return messageDate.getDate() + ' ' + months[messageDate.getMonth()] + ' ' + messageDate.getFullYear();
    }
  };

  let message = {
    conversationId: selectedChat?.id,
    fromId: currentUser.phoneId,
    toId: selectedChat?.numberPhone,
    type: 'text',
    message: newMessage,
    userId: currentUser.id,
    createdAt: new Date()
  }

  //Function send message
  const handleSendMessage = async() => {
    if (newMessage.trim() !== "") {
      socket.emit('send_msg', message);
      setMessageSent([...messageSent,1])
      setNewMessage("");
      handleCloseReply()
    }
  };
  const handleMessageChange = (e) => {
    const lines = e.target.value.split('\n').length;
    const maxRows = 5;
    e.target.style.height = lines <= maxRows ? `${lines * 1.2}em` : `${maxRows * 1.2}em`;
    setNewMessage(e.target.value);
  };


  //Function AutoScroll to bottom
  useEffect(() => {
    if(messages || displayedRows){
      chatContainerRef.current.scrollTop = chatContainerRef?.current?.scrollHeight
    }
    //Function disable chat
    const DisableChat = () =>{
      if(displayedRows?.length > 0){
        var lastMsgDate = new Date(displayedRows[displayedRows?.length - 1].createdAt)
        const timeDifferences = Date.now() - lastMsgDate.getTime();
        const different = timeDifferences <= 86400000;
        if (different) {
          setDisableChat(false)
        }
        else if(!selectedChat?.startChat){
          setDisableChat(true)
        }else{
          setDisableChat(true)
        }
      }
    }
    DisableChat()
  }, [messages, displayedRows, selectedChat]);

  
  //Function back to chat list
  const handleBackToChatList = () => {
    setSelectedChat(null);
  };

  
  useEffect(() => {
    //Get Message first time and when refreshChatMessage emitted
    const getMessage = (conversationId) =>{
      let param = {
        conversationId: conversationId
      }
      socket.emit("getChatMessage", param, (response) =>{
        if(response.status === 200){
          setMessages(response.data)
        }
      })
    }
    socket.on("refreshChatMessage", (message) =>{
      if(message === id.current){
        getMessage(id.current);
      }
    });
  }, [socket])

  useEffect(() => {
    //Get Message first time and when refreshChatMessage emitted
    const getMessage = (conversationId) =>{
      let param = {
        conversationId: conversationId
      }
      socket.emit("getChatMessage", param, (response) =>{
        if(response.status === 200){
          setMessages(response.data)
        }
      })
    }
    setNewMessage('')
    setMessages([])
    getMessage(selectedChat?.id)
    id.current = selectedChat?.id
  }, [selectedChat, socket]);

   //=========================SEND TEMPLATE START CHAT==============
  const[fileTemplate, setFileTemplate] = useState()
  const[fileTemplateName, setFileTemplateName] = useState('')
  const[fileTemplateUrl, setFileTemplateUrl] = useState('')
  const handleStartChat = () => {
    setOpenStartChat(!openStartChat)
    setTemplate(null)
  }
  const handleUploadFileTemplate = (e) =>{
    try {
        setFileTemplate(e.target.files[0])
        setFileTemplateUrl(URL.createObjectURL(e.target.files[0]))
        setFileTemplateName(e.target.files[0].name)
    } catch (error) {
        
    }
}

  const headerParams = template?.headerParams ? JSON.parse(template.headerParams) : null;
  const bodyParams = template?.bodyParams ? JSON.parse(template.bodyParams) : null;
  const footerParams = template?.footerParams ? JSON.parse(template.footerParams) : null;

  const [headerInputValues, setHeaderInputValues] = useState(Array(headerParams?.length).fill(''));
  const [bodyInputValue, setBodyInputValue] = useState(Array(bodyParams?.length).fill(''));
  const [footerInputValues, setFooterInputValues] = useState(Array(footerParams?.length).fill(''));

  const handleHeaderInputChange = (index, value) => {
      const newHeaderInputValues = [...headerInputValues];
      newHeaderInputValues[index] = value;
      setHeaderInputValues(newHeaderInputValues);
  };
  const handleBodyInputChange = (index, value) => {
      const newBodyInputValues = [...bodyInputValue];
      newBodyInputValues[index] = value;
      setBodyInputValue(newBodyInputValues);
  };

  const handleFooterInputChange = (index, value) => {
      const newFooterInputValues = [...footerInputValues];
      newFooterInputValues[index] = value;
      setFooterInputValues(newFooterInputValues);
  };
  useEffect(() => {
    socket.emit("allTemplates", {},(response) =>{
        if(response){
            setTemplateList(response)
        }
    })
  }, [socket]);

  const handleSelectTemplate = (data) =>{
    setTemplate(JSON.parse(data))
  }
  const replacePlaceholders = (text, params, inputValues) => {
    if (!params) {
        return text;
    }

    let replacedText = text;
    params.forEach((p, index) => {
        const placeholder = `{{${index + 1}}}`;
        const inputValue = inputValues[index];

        replacedText = replacedText.split(placeholder).join(inputValue);
    });

    return replacedText;
};

  let headerPreview
  if(template?.header.match(/({{\d+}})/gm)?.length){
        headerPreview = replacePlaceholders(template?.header, headerParams, headerInputValues);
    }else{
        switch(template?.header){
            case 'IMAGE':
                if(fileTemplate){
                    headerPreview = <img alt='' src={fileTemplateUrl} className='object-cover rounded-lg w-full h-32'/>
                }else{
                    headerPreview = <img alt='' src={imageThumbnail} className='object-cover rounded-lg w-full h-32'/>
                }
                break;
            case 'VIDEO':
                if(fileTemplate){
                    headerPreview = 
                    <video className='object-cover rounded-lg w-full h-32'>
                        <source src={fileTemplateUrl} type='video/mp4' controls/>
                    </video>
                }else{
                    headerPreview = <img alt='' src={videoThumbnail} className='object-cover rounded-lg w-full h-32 bg-[#EFEFEF]'/>
                }
                break;
            case 'DOCUMENT':
                if(fileTemplate){
                    headerPreview = 
                        // <iframe src={fileTemplateUrl} className='object-cover rounded-lg w-60 h-32' type="application/pdf"></iframe >
                        <div className='h-32 bg-white rounded-lg p-1 overflow-hidden flex justify-center'>
                            <Document
                                file={fileTemplateUrl}
                                onLoadSuccess={(p) => {}}
                            >
                                <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} width={300} devicePixelRatio={1.2}
                                onLoadError={(error) => alert('Error while loading document! ' + error.message)}/>
                            </Document>
                        </div>
                }else{
                    headerPreview = 
                    <div className='w-full min-w-60 h-32 bg-[#EFEFEF] flex justify-center items-center rounded-lg'>
                        <img alt='' src={documentThumbnail} className='object-cover h-10'/>
                    </div>
                }
                break;
            default:
                headerPreview = template?.header
        }
    }

    const bodyPreview = replacePlaceholders(template?.body, bodyParams, bodyInputValue);
    const footerPreview = replacePlaceholders(template?.footer, null, footerInputValues);

  let dataTemplate = {
    templateName: template?.id,
    language: template?.language,
    headerParams: headerInputValues,
    bodyParams: bodyInputValue,
    footerParams: footerInputValues,
    customer: {
      numberPhone: selectedChat?.numberPhone
    },
    from: currentUser.phoneId,
    createdAt: new Date(),
    user:{
        id: currentUser.id,
        role: currentUser.roles,
        agent: currentUser.agent,
    },
  }
  
  const handleSendTemplate = async() =>{
    if(fileTemplate){
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName",fileName)
      try {
          const response = await API.post('/api/upload', formData)
          if(response.status === 200){
            const data = response.data
            dataTemplate.headerParams = {
              link: data.url,
              fileName: data.fileName,
              type: headerParams.type
            }
          }
      } catch (error) {
      console.log(error)
      }
  }
    try {
        socket.emit("sendMessageTemplates", dataTemplate,(response) =>{
            if(response){
              handleStartChat()
            }
        })
    } catch (error) {
        handleStartChat()
        console.log(error)
    }
  }
  //Upload Media
  const[file, setFile] = useState()
  const[fileUrl, setFileUrl] = useState('')
  const[fileName, setFileName] = useState('')
  const[fileType, setFileType] = useState('')
  const[openDialogMedia, setOpenDialogMedia] = useState(false)
  const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
  const [caption, setCaption] = useState('')    
  const handleOpenDialogMedia = () => {
    if(openDialogMedia){
      if(file && fileName){
        setFile(null)
        setFileName(null)
        setNumPages(null);
        setPageNumber(1)
        setFileType(null)
        setFileUrl(null)
      }
    }
    setOpenDialogMedia(!openDialogMedia)
  }
  const handleUploadFile = (e) =>{
    try {
      setFileType(e.target.files[0].type)
      setFile(e.target.files[0])
      setFileUrl(URL.createObjectURL(e.target.files[0]))
      setFileName(e.target.files[0].name)
      handleOpenDialogMedia()
    } catch (error) {
      
    }
  }
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};
	const goToPrevPage = () =>
		setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

	const goToNextPage = () =>
		setPageNumber(
			pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
		);
  const sendMediaMessage = async (e) => {
    let mediaData = {
      conversationId: selectedChat?.id,
      fromId: currentUser.phoneId,
      toId: selectedChat?.numberPhone,
      caption: caption,
      userId: currentUser.id,
      createdAt: new Date(),
      fileName: ''
    }
    switch(fileType){
      case 'application/pdf':
        mediaData.type = 'document'
        mediaData.file = {
          caption: caption,
          filename: fileName
        }
        break;
      case 'image/jpeg':
        mediaData.type = 'image'
        mediaData.file = {
          caption: caption,
        }
        break;
      case 'image/png':
        mediaData.type = 'image'
        mediaData.file = {
          caption: caption,
        }
        break;
      default:
        break;
    }
    if(file){
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName",fileName)
      try {
        const response = await API.post('/api/upload', formData)
        if(response.status === 200){
          const data = response.data
          mediaData.file.link = data.url
          mediaData.fileName = data.fileName
          socket.emit('send_media_msg', mediaData)
          handleOpenDialogMedia()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  //REPLY MESSAGE
  const [reply, setReply] = useState(false)
  const [repliedMsg, setRepliedMsg] = useState({})

  const handleCloseReply = () =>{
    setReply(false)
    setRepliedMsg({})
  } 
  const handleReplyMessage = (value) =>{
    setReply(true)
    setRepliedMsg(value)
  }
  let repliedMedia
  if(repliedMsg.files){
    repliedMedia = JSON.parse(repliedMsg.files)
  }
  
  if(reply){
    message.context = repliedMsg
  }


  //function shortcut
  const [showPopover, setShowPopover] = useState(false)
  useEffect(() => {
    if(newMessage.substring(0,1) === '/'){
      setShowPopover(true)
    }else{
      setShowPopover(false)
    }
  }, [newMessage, selectedChat])

  return (
    <>
    {displayedRows ?(
      <div className={`flex w-full ${showInfo ? 'lg:w-[55%] 2xl:w-[60%]' : 'lg:w-[75%] 2xl:w-[80%]'}`} tabIndex="0" onKeyDown={(e) => {
        if (e.key === "Escape" ) {
          handleBackToChatList();
        }
      }}>
        <div className="flex-1 flex flex-col overflow-hidden">
          <UserProfile username={selectedChat?.name} handleInfo={handleInfo} q={q} setQ={setQ}/>
          {/* Chat Messages */}
          <div className="flex-1 bg-[#EEF0E5] bg-chatbg overflow-x-hidden overflow-y-scroll break-words" ref={chatContainerRef}>
            {displayedRows?.length > 0 && displayedRows.map((message, index) => {
              const dateLabel = formatDateLabel(message?.createdAt);
              const showDateLabel = dateLabel !== lastDisplayedDate;
              lastDisplayedDate = dateLabel;
              let files
              if(message.files){
                files = JSON.parse(message.files)
              }
              const isCurrentUser = message?.fromId?.toString() !== selectedChat?.numberPhone;
              const isStickerOrAudio = files?.type === 'sticker' || files?.type === 'audio';
              const messageContainerClasses = `flex items-end ${isCurrentUser ? 'justify-end' : 'justify-start'} mb-4`;
              const messageContentClasses = `max-w-xs rounded-lg p-4 ${isCurrentUser ? 'bg-[#61A3BA] text-white' : 'bg-[#597E52] text-white ml-5'} ${isStickerOrAudio ? 'bg-transparent' : ''}`;
              let replyContext
              if(message.context){
                replyContext = JSON.parse(message.context)
              }
              let interactive
              if(message.type === 'interactive'){
                interactive = JSON.parse(message.interactive)
              }
              return (
                <div key={index}>
                  {showDateLabel && (
                    <div className="relative date-chip">
                      <Chip variant="ghost" value={dateLabel} className="flex rounded-full justify-center w-28 p-2 mx-auto my-5 capitalize font-thin" />
                    </div>
                  )}
                  <div className={messageContainerClasses}>
                    {isCurrentUser && (
                    <div className='mr-1 mb-1'>
                      <p className=" flex items-center justify-end text-xs text-white bg-opacity-20 hover:bg-opacity-50 bg-black rounded-lg p-1 gap-0.5">
                        {message?.time} 
                        <BiCheckDouble className='text-base' />
                      </p>
                    </div>
                    )}
                    <div className={messageContentClasses}>
                      <div className='whitespace-pre-line hyphens-auto'>
                        {replyContext && (
                          <div className='w-full min-h-10 text-sm mb-1'>
                            <div className={`p-3 rounded-lg ${isCurrentUser ? 'bg-[#6B98A8]' : 'bg-[#779c70]'}`}>
                              <p className='font-bold'>
                                {replyContext.fromName ? replyContext.fromName : currentUser.username}
                              </p>
                              {replyContext.message === "image" && (
                                <div className='w-full flex justify-between'>
                                    <div className='flex items-center gap-2'>
                                        <FaCamera className='text-xl'/>
                                        <p>
                                            Photo
                                        </p>
                                    </div>
                                </div>
                                )}
                                {replyContext.message === "document" && (
                                <div className='w-full flex items-center gap-2'>
                                    <FaFileAlt className='text-xl'/>
                                    <p>
                                        {repliedMedia.fileName.length > 100 ? `${repliedMedia.fileName.substring(0,100).trim}...` : repliedMedia.fileName}
                                    </p>
                                </div>
                                )}
                                {replyContext.message === "audio" && (
                                <div className='w-full flex items-center gap-2'>
                                    <FaMicrophone className='text-xl'/>
                                    <p>
                                        Audio
                                    </p>
                                </div>
                                )}
                                {replyContext.message === "video" && (
                                <div className='w-full flex justify-between'>
                                    <div className='flex items-center gap-2'>
                                        <FaVideo className='text-xl'/>
                                        <p>
                                            Video
                                        </p>
                                    </div>
                                </div>
                                )}
                              <p>
                                {replyContext.message === "image" || replyContext.message === "document" || replyContext.message === "audio" || replyContext.message === "video" || replyContext.message === "sticker" ? "" : replyContext.message.length > 40 ? `${replyContext.message.substring(0,40).trim()}`: replyContext.message}
                              </p>
                            </div>
                          </div>
                        )}
                        <div className='flex w-full justify-between gap-3'>
                          <div className=''>
                            {message.files && (
                              <MediaMessage message={message} selectedChat={selectedChat}/>
                            )}
                            {message.message === "image" || message.message === "document" || message.message === "audio" || message.message === "video" || message.message === "sticker" ? '' : message.type === 'interactive' && interactive ? (
                              <div className='w-full h-full'>
                                <InteractiveMessage interactive={interactive} showInfo={showInfo}/>
                              </div>
                            ) : message.message?.split("\n\n")[0].toLowerCase()  === "image" || message.message?.split("\n\n")[0].toLowerCase()  === "document" || message.message?.split("\n\n")[0].toLowerCase()  === "video" ? message.message.substr(message.message.indexOf("\n\n") + 1).trim() : message.message }
                          </div>
                        </div>
                      </div>
                      {/* {isStickerOrAudio && (
                        <div className="flex justify-end mt-2 opacity-80">
                          <div className={badgeClasses}>
                            <p className="text-xs">{message?.time}</p>
                            {isCurrentUser ? <BiCheckDouble /> : ""}
                          </div>
                        </div>
                      )} */}
                    </div>
                    {!isCurrentUser && (
                      <div className='ml-1 mb-1'>
                        <div className={`transition-all opacity-25 hover:opacity-100 ${disableChat ? 'hidden' : "block"}`}>
                          <Button className='h-5 w-6 p-1 bg-opacity-50' onClick={() => handleReplyMessage(message)}>
                            <FaReply className='ml-0.5'/>
                          </Button>
                        </div>
                        <div className='mt-1'>
                          <p className="text-xs text-white bg-opacity-20 hover:bg-opacity-50 bg-black rounded-lg p-1">{message?.time}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <hr className='h-0.5 bg-gray-100 border-0 rounded  dark:bg-gray-700'/>
          {/* Message Input */}
          {reply && (
          <div className=' flex justify-between w-full min-h-20 p-3 text-gray-700'>
            <div className='h-full w-full rounded-lg bg-[#EEF0E5] bg-opacity-40 p-3 gap-3 flex justify-between'>
                <div className='w-full flex flex-col justify-center gap-1'>
                    <p className={`font-bold`}>
                        {repliedMsg.fromName}
                    </p>
                    {repliedMsg.message === "image" && (
                    <div className='w-full flex justify-between'>
                        <div className='flex items-center gap-2'>
                            <FaCamera className='text-xl'/>
                            <p>
                                Photo
                            </p>
                        </div>
                    </div>
                    )}
                    {repliedMsg.message === "document" && (
                    <div className='w-full flex items-center gap-2'>
                        <FaFileAlt className='text-xl'/>
                        <p>
                            {repliedMedia.fileName.length > 100 ? `${repliedMedia.fileName.substring(0,100).trim}...` : repliedMedia.fileName}
                        </p>
                    </div>
                    )}
                    {repliedMsg.message === "audio" && (
                    <div className='w-full flex items-center gap-2'>
                        <FaMicrophone className='text-xl'/>
                        <p>
                            Audio
                        </p>
                    </div>
                    )}
                    {repliedMsg.message === "video" && (
                    <div className='w-full flex justify-between'>
                        <div className='flex items-center gap-2'>
                            <FaVideo className='text-xl'/>
                            <p>
                                Video
                            </p>
                        </div>
                    </div>
                    )}
                    <p>
                        {repliedMsg.message === "image" || repliedMsg.message === "document" || repliedMsg.message === "audio" || repliedMsg.message === "video" || repliedMsg.message === "sticker" ? "" : repliedMsg.message.length > 150 ? `${repliedMsg.message.substring(0,180).trim()}.....`: repliedMsg.message}
                    </p>
                </div>
                <div className=''>
                    {repliedMsg.message === "image" && (
                    <div className='w-full flex justify-between'>
                        <div>
                            <img alt='' src={repliedMedia.url} className='h-16 w-16 object-cover rounded-lg' />
                        </div>
                    </div>
                    )}
                    {repliedMsg.message === "video" && (
                    <div className='w-full flex justify-between'>
                        <div>
                            <video className="h-16 w-16 object-cover rounded-lg" preload="metadata">
                                <source src={repliedMedia.url} type='video/mp4' />
                            </video>
                        </div>
                    </div>
                    )}
                </div>
            </div>
            <div className='flex items-center p-5'>
                <Button className='w-10 p-2 flex justify-center items-center' onClick={handleCloseReply}>
                    <IoCloseSharp className='text-2xl'/>
                </Button>
            </div>
        </div>
          )}
          <Popover open={showPopover}>
            <PopoverHandler>
              <div className='h-0.5 opacity-0 select-none' draggable={false}>Shortcut</div>
            </PopoverHandler>
            <PopoverContent className='min-h-[100px] lg:w-[40%] w-[90%] absolute'>
              <PopoverShortcut currentUser={currentUser} newMessage={newMessage} socket={socket} setNewMessage={setNewMessage}/>
            </PopoverContent>
          </Popover>
          <div className={`bg-white border-gray-200 p-5 flex justify-between min-h-20 max-w-[${screenSize}px]`}>
          {!disableChat ? ( 
            <>
              <div className='flex items-center justify-between gap-2 p-1'>
                <input id="file_input" className='hidden' type='file' accept='.pdf' 
                onChange={(e) =>handleUploadFile(e)}
                />
                <label htmlFor='file_input'>
                  <IoDocumentAttachOutline className='text-2xl text-gray-500 hover:text-gray-800 cursor-pointer'/>
                </label>
                <input id="image_input" className='hidden' type='file' accept='.png, .jpg' 
                onChange={(e) =>handleUploadFile(e)}
                />
                <label htmlFor='image_input'>
                  <IoImageOutline className='text-2xl mt-0.5 text-gray-500 hover:text-gray-800 cursor-pointer'/>
                </label>
              </div>
              <div className="w-full">
                <AutoResizeTextarea
                    value={newMessage}
                    onChange={handleMessageChange}
                    placeholder="Type your message?..."
                    className="min-h-full border-none bg-gray-100"
                    containerProps={{
                      className: "grid h-full",
                    }}
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.ctrlKey) {
                        handleSendMessage();
                      }
                    }}
                    maxLength={1024}
                  />
              </div>
              <Button
                  onClick={handleSendMessage}
                  size="sm"
                  color={newMessage ? "gray" : "blue-gray"}
                  disabled={!newMessage}
                  className="right-1 top-1 rounded flex items-center"        
              >
                  Send
              </Button>
            </>
          ):(
            <div className="flex justify-between w-full items-center gap-5">
              <p className='text-gray-700'>
                There is no active session in this room. You can start a new session by clicking the Start Chat button
              </p>
              <Button className='rounded-full bg-green-400' onClick={handleStartChat}>
                Start Chat
              </Button>
            </div>
          )}
          </div>
        </div>
        <Dialog open={openStartChat} handler={handleStartChat} className='dialog start chat' size='xl'>
          <DialogHeader>Opening a Chat Session</DialogHeader>
          <DialogBody>
            <div className='flex w-full'>
              <div className='w-[60%]'>
                <div className='p-5 space-y-5 h-[90%] drop-shadow-lg'>
                  <div className='bg-[#FFECB0] rounded-lg flex justify-center items-center gap-5 p-5'>
                    <IoWarningOutline className='text-4xl text-red-400'/>
                    <p className='text-sm font-normal break-words'>
                      To start this session, you will be charged based on your conversation category.
                    </p>
                  </div>
                  <div className='space-y-1'>
                    <p>Select Template</p>
                    <Select className='!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900' 
                    labelProps={{
                      className: "hidden",
                    }}
                    onChange={handleSelectTemplate}
                    >
                      {templateList.map((template) => (
                          <Option value={JSON.stringify(template)} key={template?.id}>
                              {template.name}
                          </Option>
                      ))}
                    </Select>    
                  </div>
                  <div className='w-full'>
                    {template && (template?.header === 'IMAGE' || template?.header === 'VIDEO' || template?.header === 'DOCUMENT') ? 
                      <div className='bg-gray-200 p-2 mt-5 rounded-md'>
                          <p className='text-lg font-semibold'>
                              Header content
                          </p>
                          <p className='text-sm text-gray-700'>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.</p>
                          <div>
                              <div className='flex items-center justify-start gap-3 mt-2'>
                                  <p className='capitalize'>
                                      {template?.header.toLocaleLowerCase()}
                                  </p>
                                  <div>
                                      {!file ? (
                                          <div>
                                              <input id="file_input" className='hidden' type='file' accept={template?.header === 'IMAGE' ? '.png, .jpg': template?.header === 'VIDEO'? '.mp4' : template?.header === 'DOCUMENT' ? '.pdf' :''} 
                                              onChange={handleUploadFileTemplate}/>
                                              <label htmlFor='file_input' className='flex items-center text-white bg-gray-900 rounded-lg px-2 py-3 justify-center gap-2 text-xs font-semibold uppercase hover:drop-shadow-xl cursor-pointer hover:bg-opacity-90 transition-all'>
                                                  <MdOutlineCloudUpload className='text-xl'/>
                                                  {template?.header === 'IMAGE' ? 'Choose JPG or PNG File': template?.header === 'VIDEO'? 'Choose MP4 File' : template?.header === 'DOCUMENT' ? 'Choose PDF File' :''}
                                              </label>
                                          </div>
                                      ):(
                                          <div>
                                              <div className='flex items-center text-white bg-gray-900 rounded-lg px-2 py-3 justify-center gap-2 text-xs  hover:drop-shadow-xl cursor-pointer hover:bg-opacity-90 transition-all'>
                                                  <div className='max-w-5 max-h-5 overflow-hidden'>
                                                  {template?.header === 'IMAGE' ? 
                                                  <FaImage/>
                                                  : template?.header === 'VIDEO'? 
                                                  <FaVideo/>
                                                  : template?.header === 'DOCUMENT' ? 
                                                  <IoDocument/>
                                                  :''}
                                                  </div>
                                                  {fileTemplateName}
                                                  <button className='p-1 hover:bg-white hover:text-black hover:rounded-full transition-all'
                                                  onClick={() =>{
                                                      try {
                                                          setFileTemplate('')
                                                          setFileTemplateName('')
                                                          setFileTemplateUrl('')
                                                      } catch (error) {
                                                          
                                                      }
                                                  }}
                                                  >
                                                      <IoCloseCircle />
                                                  </button>
                                              </div>
                                          </div>
                                      )}
                                  </div>
                              </div>
                          </div>
                      </div>
                      :
                      <ParameterSection
                          title="Header"
                          content={template?.header}
                          params={headerParams}
                          onInputChange={handleHeaderInputChange}
                      />
                      }
                      <ParameterSection
                          title="Body"
                          content={template?.body}
                          params={bodyParams}
                          onInputChange={handleBodyInputChange}
                      />
                      <ParameterSection
                          title="Footer"
                          content={template?.footer}
                          params={footerParams}
                          onInputChange={handleFooterInputChange}
                      />
                  </div>
                </div>
                <div className='w-full flex justify-center gap-5 items-center h-[10%]'>
                  <Button className='w-40 flex justify-center rounded-full' variant='outlined' onClick={handleStartChat}>
                    Cancel
                  </Button>
                  <Button className='w-40 flex justify-center rounded-full' disabled={!template} onClick={handleSendTemplate}>
                    Send
                  </Button>
                </div>
              </div>
              <div className='w-[40%]'>
              {template && (
                <MessagePreview headerPreview={headerPreview} bodyPreview={bodyPreview}
                footerPreview={footerPreview} size='w-full'/>
              )}
              </div>
            </div>
          </DialogBody>
        </Dialog>
        <Dialog open={openDialogMedia} handler={handleOpenDialogMedia} size='lg' className=' bg-gray-100'>
          <DialogBody>
            {file && fileName && (
              <>
                <div className='h-[80vh] flex flex-col justify-between'>
                  <div className='w-full flex p-2 justify-center font-semibold'>
                    <p>
                      {fileName}
                    </p>
                  </div>
                  <div className='w-full '>
                    {fileType === 'application/pdf' ?
                      <div className='space-y-8'>
                        <div className='flex justify-center'>
                          <Document
                            file={file}
                            onLoadSuccess={onDocumentLoadSuccess}
                          >
                            <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} width={750}
                            onLoadError={(error) => alert('Error while loading document! ' + error.message)}/>
                          </Document>
                        </div>
                        <div className='space-y-5'>
                          {numPages > 1 &&(
                            <div className='flex justify-center gap-5'>
                              <Button onClick={goToPrevPage}>
                                <GrLinkPrevious/>
                              </Button>
                              <Button onClick={goToNextPage}>
                                <GrLinkNext/>
                              </Button>
                            </div>
                          )}
                          <div className='flex justify-center'>
                            <p>
                              Page {pageNumber} of {numPages}
                            </p>
                          </div>
                        </div>
                      </div>
                    : fileType.substring(0,5) === 'image' ?
                      <div className='h-[60vh] flex flex-col justify-between'>
                        <div className='flex justify-center'>
                            <img alt='' src={fileUrl} className='h-[50vh] rounded-lg hover:drop-shadow-2xl'/>
                        </div>
                        <div className='flex justify-center'>
                          <div className='w-[85%]'>
                            <InputComponent label={'Type your message'} onInputChange={setCaption}/>
                          </div>
                        </div>
                      </div>
                    :''}
                  </div>
                  <hr />
                  <div className='flex justify-center gap-10'>
                    <Button className='w-20 flex justify-center items-center' onClick={sendMediaMessage}>
                      <IoSend className='text-xl'/>
                    </Button>
                  </div>
                </div>
              </>
            )}
          </DialogBody>
        </Dialog>
      </div>
    ) :
    (
      <Loading/>
    )
    }
    </>
  );
};

export default ChatRoom;