import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  Typography,
  IconButton,
  Tooltip,
  Input,
  Button,
  Alert,
} from "@material-tailwind/react";
import { GoDotFill } from "react-icons/go";
import { IoPersonOutline, IoCloseCircleOutline } from 'react-icons/io5';
import { FaSearch } from "react-icons/fa";
import { FaExclamation } from "react-icons/fa6";
import { BiMailSend } from "react-icons/bi";
import { IoMdDoneAll } from "react-icons/io";
import API from '../API/API';
import avatar from '../img/default_avatar.svg'
const InputCustom = ({value, disabled, onChange, label}) =>{
  return(
    <Input
      className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
      labelProps={{
        className: "hidden",
      }}
      containerProps={{ className: "min-w-[100px]" }}
      disabled={disabled}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      label={label}
    />
  )
}
const ChatList = ({ chats, onSelectChat, currentUser, getCustomer }) =>{

   //=======================LIVE SEARCH=======================
    const [open, setOpen] = useState(false)
    const [q, setQ] = useState("");
    const [filterStatus, setFilterStatus] = useState('');
    const [totalUnserved, setTotalUnserved] = useState('')
    const userRoles = currentUser.roles
    const filteredRows = chats.filter(chat =>
      (chat.name?.toLowerCase().includes(q?.toLowerCase()) || chat.numberPhone?.includes(q)) &&
      (filterStatus ? chat.status?.toLowerCase() === filterStatus?.toLowerCase() : true)
    );

    //=====================ERROR HANDLING===================
    const [alert, setAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')

    const handleError = (message) =>{
      setAlert(true)
      setAlertMessage(message)
    }

    if(alert){
      setTimeout(() => {
      setAlert(false)
      }, 5000);
    }

    useEffect(() => {
      const getUnservedData = async() => {
        try {
          const response = await API.get('/api/dashboard')
            if(response.status === 200){
              setTotalUnserved(response?.data?.data[0].unservedConversation)
            }
        } catch (error) {
          if(error.message !== 'Network Error'){
            handleError(error.response?.data?.message)
          }else{
            handleError('Network Error')
          }
        }
      }
      getUnservedData()
    }, [chats]);

    const displayedRows = q || filterStatus ? filteredRows : chats;

    const handleOpenSearch = () => setOpen(!open);

    const filterChat = (status) => {
      setFilterStatus(status);
    };
   
  return (
    <div className="rounded-none shadow-none h-full w-full lg:w-[25%] 2xl:w-[20%]">
      <div className="p-3 h-[9%] flex items-center">
        <div className="w-full flex align-items-start justify-between gap-5 drop-shadow-lg">
          {open ? 
          <InputCustom value={q} onChange={setQ}/>
          : 
          <Typography variant='h4' className="flex items-center text-green-500">
              Inbox
          </Typography>
          }
          <div className="flex justify-between">
              <IconButton className='bg-green-500' onClick={handleOpenSearch}>
                {open ? <IoCloseCircleOutline className='text-2xl'/> : <FaSearch className='text-xl'/>}
              </IconButton>
          </div>
        </div>
      </div>
      <div className='md:h-[11%] h-[15%] flex items-centerw-full'>
        <div className='p-3 w-full h-full bg-green-500 rounded-lg'>
          <div className='flex justify-between items-center p-1 h-[90%]'>
            <div className='flex flex-col justify-center items-center'>
              <IconButton className='bg-white text-green-500' onClick={() => filterChat('')}>
                <IoPersonOutline className='text-xl'/>
              </IconButton>
              <p className='text-sm font-semibold text-white'>
                All
              </p>
            </div>
            <div className='flex flex-col justify-center items-center'>
            <IconButton className='bg-white text-green-500' onClick={() => filterChat('unserved')}>
              <FaExclamation className='text-xl'/>
            </IconButton>
              <p className='text-sm font-semibold text-white'>
                Unserved
              </p>
            </div>
            <div className='flex flex-col justify-center items-center'>
            <IconButton className='bg-white text-green-500' onClick={() => filterChat('served')}>
              <BiMailSend className='text-xl'/>
            </IconButton>
              <p className='text-sm font-semibold text-white'>
                Served
              </p>
            </div>
            <div className='flex flex-col justify-center items-center'>
            <IconButton className='bg-white text-green-500'onClick={() => filterChat('resolved')}>
              <IoMdDoneAll className='text-xl'/>
            </IconButton>
              <p className='text-sm font-semibold text-white'>
                Resolved
              </p>
            </div>
          </div>
          <div className='w-full flex justify-center items-center text-xs capitalize h-[10%]'>
            <p className='text-white'>
              {filterStatus}
            </p>
          </div>
        </div>
      </div>
      <div className='h-[65%] md:h-[70%] overflow-y-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]'>
        <hr className='w-full'/>
        {displayedRows.map((chat) => {
          return(
            <List key={chat?.id}>
              <Tooltip content={chat?.name} placement="bottom">
                <ListItem  onClick={() => onSelectChat(chat)}>
                  <div className='flex justify-between items-center w-full'>
                    <div className='flex gap-3 w-[70%] items-center'>
                      {chat.notification ? 
                      <GoDotFill className='text-red-400 text-xl -mx-2 mb-6'/> : ''}
                      <img alt='' src={avatar} className='h-10 rounded-full bg-[#F9E8C9]' />
                      <div>
                        <p className={`${chat.notification ? 'font-semibold' : 'font-semibold'} text-base`}>
                          {chat?.name?.length > 20 ? chat?.name.replace(/\n/g, " ").substring(0,20)+"..." : chat?.name.replace(/\n/g, " ")}
                        </p>
                        <p className={`${chat.notification ? 'font-bold' : 'font-normal'} text-sm`}>
                          {chat?.message?.length > 20 ? chat?.message?.replace(/\n/g, " ").substring(0,20)+"..." : chat?.message?.replace(/\n/g, " ")}
                          {!chat?.message && ' '}
                        </p>
                      </div>
                    </div>
                    <div className='w-[30%] space-y-1'>
                      <p className={`text-xs flex justify-end ${chat.notification ? 'font-semibold' : 'font-thin'}`}>
                        {chat?.date}
                      </p>
                      <p className={` ${chat.notification ? 'font-semibold' : 'font-thin'} text-xs flex justify-end capitalize ${chat.status === 'unserved' ? 'text-red-400' : chat.status === 'served' ? 'text-light-blue-500' : chat.status === 'resolved' && 'text-green-400'}`}>
                        {chat?.status}
                      </p>
                    </div>
                  </div>
                </ListItem>
              </Tooltip>
              <hr className='h-0.5 rounded-full mx-2'/>
            </List>
          )}
          )
        }
      </div>
      {userRoles === 'agent' ? 
          <div className="p-3 flex flex-col justify-center w-full h-[10%] items-center">
            <p className='font-semibold'>Unserved Customers: {totalUnserved}</p>
            <Button className='w-full h-14 bg-green-500' onClick={getCustomer}>
              Get Customer
            </Button>
          </div>
        : 
        <div className="p-3 h-[10%]">
          <div className="flex justify-center gap-2 border rounded-lg border-green-500 h-full w-full items-center">
              <p className='font-semibold'>Unserved Customers:</p>  {totalUnserved}
          </div>
        </div>
      }
      <div className='absolute lg:right-5 z-10 bottom-5 p-5 lg:w-[21%] w-full'>
        <Alert open={alert} color="red">
          <p className='font-bold'>
            Error
          </p>
          <p>
            Message : {alertMessage}
          </p>
        </Alert>
      </div>
    </div>
  )
}

export default ChatList;
