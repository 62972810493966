import React, { useEffect, useState } from 'react'
import {Home, Chat, Broadcast, Customers, Settings, Register, TestDev, SignIn} from './pages'
import {Loading, NavBar, Sidebar} from './components'
import { Routes, Route } from 'react-router-dom';
import { useStateContext } from './contexts/ContextProvider';
import API from './API/API';
import { io } from "socket.io-client";
import { Alert } from '@material-tailwind/react';

const socket = io(process.env.REACT_APP_serverUrl, {
  withCredentials: true,
  transports : ['websocket', 'polling', 'flashsocket'],
});
// const socket = io("http://192.168.100.173:4000", {
//   withCredentials: true,
//   transports : ['websocket', 'polling', 'flashsocket']
// });


socket.on('connection', () => {
    console.log('user connected');
    socket.on("ping", (message) => {
      // setTimeout(() => {
      //   socket.connect();
      // }, 1000);
    });
    // console.log('user connected: ' + socket.id);
});

socket.on('disconnect', () => {
  setTimeout(() => {
    socket.connect();
  }, 1000);
});
socket.on('error', (e) => {
  setTimeout(() => {
    socket.connect();
  }, 1000);
});
socket.on('connect_error', (e) => {
  setTimeout(() => {
    socket.connect();
  }, 1000);
});

const App = () => {
  const { activeNavbar, activeSidebar, currentUser, loggedIn, setCurrentUser, editUser } = useStateContext();
  const [loading, setLoading] = useState(true)
  //=====================ERROR HANDLING===================
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const handleError = (message) =>{
    setAlert(true)
    setAlertMessage(message)
  }

  if(alert){
    setTimeout(() => {
    setAlert(false)
    }, 5000);
  }
  useEffect(() => {
    API.get('/api/profile').then(response => {
      if(response.status === 200){
        setCurrentUser(response.data);
      }
    }).catch((e) =>{
      if(e.response?.status !== 403){
        handleError(e.message)
        console.log(e)
      }
    }).finally(
      setLoading(false)
    );
  }, [loggedIn, editUser, setCurrentUser]);
  
  if(loading){
    return <Loading />  
  }

  return (
    <div className='max-h-screen'>
      {currentUser ? (
        <>
          <div className='h-[7vh] w-full'>
            {activeSidebar &&(
              <Sidebar/>
            )}
            {activeNavbar &&(
              <NavBar/>
            )}
          </div>
          <div className="h-[93vh]">
            <div className='w-full h-full'>
              <Routes>
                {currentUser.roles !== 'agent' ? 
                <Route path='/' element={<Home/>}/> : 
                <Route path='/' element={<Chat socket={socket}/>}/>
                }
                <Route path='/chat' element={<Chat socket={socket}/>}/>
                <Route path='/template' element={<Broadcast socket={socket}/>}/>
                <Route path='/customers' element={<Customers socket={socket}/>}/>
                <Route path='/settings' element={<Settings socket={socket}/>}/>
                <Route path='/register' element={<Register socket={socket}/>}/>
                {/* <Route path='/test' element={<TestDev/>}/> */}
              </Routes>
            </div>
          </div>
        </>
      ):(
        <>
          <div className="dark:bg-main-dark-bg">
            <div className='w-full'>
              <Routes>
                <Route path='*' element={<SignIn/>}/>
                {/* <Route path='/login' element={<SignIn/>}/>
                <Route path='/register' element={<Register socket={socket}/>}/> */}
                <Route path='/test' element={<TestDev/>}/>
              </Routes>
            </div>
          </div>
        </>
      )}
      <div className='absolute md:right-5 z-10 bottom-5 p-5 md:w-[21%] w-full'>
        <Alert open={alert} color="red">
          <p className='font-bold'>
            Error
          </p>
          <p>
            Message : {alertMessage}
          </p>
        </Alert>
      </div>
    </div>
  )
}

export default App