import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";

const AreaCharts = ({data}) => {
    return (
        <ResponsiveContainer width="99%" height="99%">
            <AreaChart
            data={data}
            margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0
            }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient id="colorConv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
              </linearGradient>
              <linearGradient id="colorChat" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area
                type="monotone"
                dataKey="conversation"
                stackId="1"
                stroke="#8884d8"
                fillOpacity={1}
                fill="url(#colorConv)"
            />  
            <Area
                type="monotone"
                dataKey="chats"
                stackId="2"
                stroke="#82ca9d"
                fillOpacity={1}
                fill="url(#colorChat)"
            />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default AreaCharts