import { Tooltip, Input, Typography, Alert  } from '@material-tailwind/react'
import React, { useState } from 'react'
import { IoInformationCircleSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import API from '../API/API'
import { useStateContext } from '../contexts/ContextProvider';
import useDocumentTitle from '../useDocumentTitle';

const InputCustom = ({value, disabled, onChange, label, type, placeholder, error, autoComplete}) =>{
  return(
      <div className='space-y-1'>
          <p className='text-sm font-semibold'>
              {label}
          </p>
          <Input
          className={`!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-2 ${error ? 'ring-red-800 focus:ring-red-800' : 'ring-transparent'} placeholder:text-gray-500`}
          labelProps={{
              className: "hidden",
          }}
          containerProps={{ className: "min-w-[100px]" }}
          disabled={disabled}
          value={value}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          autoComplete={autoComplete}
          />
      </div>
  )
}

const Login = () => {
  useDocumentTitle('Sign In - ChatApp')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const body = {
    username: username,
    password: password
  }
  const {setLoggedIn} = useStateContext();

  //=====================ERROR HANDLING===================
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const handleError = (message) =>{
    setAlert(true)
    setAlertMessage(message)
  }

  if(alert){
    setTimeout(() => {
    setAlert(false)
    }, 5000);
  }

  /**
   * ================================
   * LOGIN FUNCTION
   * ================================
   */
  async function Login(ev) {
    ev.preventDefault();
    if(username && password){
      try {
        const response = await API.post('/api/auth/signin', body);
        if(response.status === 200){
          setLoggedIn(true);
          navigate('/chat');
        }else{
          // console.log(response)
        }
      } catch (error) {
        if(error.message !== 'Network Error'){
          handleError(error.response?.data.message)
        }else{
          handleError('error coba lagi')
        }
      }
    }else{
      handleError("Please fill all the login info")
    }
  }

  return (
    <div className="flex flex-col items-center justify-center mx-auto md:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    Sign in to your account
                </h1>
                <form className="space-y-4 md:space-y-4" onSubmit={Login} >
                    <InputCustom type="text" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="username" label='Username' value={username} onChange={(e) => setUsername(e.target.value)} />
                    <div>
                      <InputCustom type="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" label='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                      <Typography
                        variant="small"
                        color="gray"
                        className="mt-2 flex items-center gap-1 font-thin text-sm"
                      >
                        <IoInformationCircleSharp/>
                        Use at least 8 characters.
                      </Typography>
                    </div>
                    
                    <Tooltip content="Click to Sign in" placement='bottom'>
                      <button type='submit' className="w-full align-middle select-none font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none">
                        Sign in
                      </button>
                    </Tooltip>
                </form>
                {/* <div className='w-full flex justify-between'>
                  <p className='text-xs text-gray-600'>
                    Want to create an account?
                  </p>
                  <Link to='/register'>
                    <p className='text-xs text-gray-600 hover:text-gray-900 cursor-pointer'>
                      Register
                    </p>
                  </Link>
                </div> */}
            </div>
        </div>
      <div className='absolute md:right-5 z-10 bottom-5 p-5 md:w-[21%] w-full'>
        <Alert open={alert} color="red">
          <p className='font-bold'>
            Error
          </p>
          <p>
            Message : {alertMessage}
          </p>
        </Alert>
      </div>
    </div>
  )
}

export default Login