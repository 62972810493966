import { Chip, Input } from '@material-tailwind/react'
import { Autocomplete } from '@mui/material'
import React, { useState } from 'react'

const customerList = [
  {
      "id": 1,
      "name": "Muhammad Zaky",
      "numberPhone": "6282211861068",
      "picture": null,
      "createdAt": "2024-01-22T04:44:06.000Z",
      "createdFrom": "organic"
  },
  {
      "id": 8,
      "name": "User1",
      "numberPhone": "6282211861061",
      "picture": null,
      "createdAt": "2024-02-03T04:41:14.000Z",
      "createdFrom": "organic"
  },
  {
      "id": 9,
      "name": "Muhammad Zaky",
      "numberPhone": "123123123",
      "picture": null,
      "createdAt": "2024-02-03T04:43:19.000Z",
      "createdFrom": "organic"
  },
  {
      "id": 10,
      "name": "Muhammad Zaky",
      "numberPhone": "521231142131",
      "picture": null,
      "createdAt": "2024-02-03T04:45:28.000Z",
      "createdFrom": "organic"
  },
  {
      "id": 11,
      "name": "Test",
      "numberPhone": "625775588783",
      "picture": null,
      "createdAt": "2024-02-03T05:43:31.000Z",
      "createdFrom": "organic"
  },
  {
      "id": 12,
      "name": "Test Add From Contact",
      "numberPhone": "628635132",
      "picture": null,
      "createdAt": "2024-02-06T04:40:15.000Z",
      "createdFrom": "contact"
  },
  {
      "id": 13,
      "name": "Test CS",
      "numberPhone": "6257755818783",
      "picture": null,
      "createdAt": "2024-02-06T08:11:21.000Z",
      "createdFrom": "organic"
  }
]

const TestDev = () => {
  const[inputNumber, setInputNumber] = useState([])
  const onDelete = (numberPhone) => () => {
      setInputNumber((inputNumber) => inputNumber.filter((v) => v.numberPhone !== numberPhone));
  };
  return (
    <div className='flex justify-center items-center shadow-none w-full h-[90vh]'>
      <div>
        <p className='font-semibold text-sm'>
          Select Customer
        </p>
        <Autocomplete
            sx={{ width: '500px', maxHeight: '48px'}}
            multiple
            filterSelectedOptions
            options={customerList}
            getOptionLabel={(option) => `${option.name} - ${option.numberPhone}`}
            value={inputNumber}
            renderOption={(props, option) => (
              <div {...props} className='px-3 py-2 rounded-lg text-gray-800 hover:bg-gray-100 cursor-pointer'>
                <li className='text-sm'>{option.name} - {option.numberPhone}</li>
              </div>
            )}
            PaperComponent={({ children }) => {
              return (
                <div className='transition-all ease-in-out p-2 mt-2 rounded-lg !border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10 drop-shadow-lg'>
                  {children}
                </div>
              );
            }}
        
            onChange={(e, newValue) => setInputNumber(newValue)}
            renderTags={() => null}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <Input {...params.inputProps} autoFocus 
                className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                labelProps={{
                  className: "hidden",
                }}
                containerProps={{ className: "min-w-[100px]" }}/>
              </div>
            )}
        />
        <div className='mt-3 max-w-[500px] flex flex-wrap gap-1'>
            {inputNumber?.length > 0 && inputNumber.map((v) =>(
                <Chip className='mt-1' key={v.numberPhone} value={`${v.name} - ${v.numberPhone}`} onClose={onDelete(v.numberPhone)}/>
            ))}
        </div>
      </div>
  </div>
  )
}

export default TestDev