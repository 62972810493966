import { Typography, Select, Option, Input,  Button, Card, CardBody, Chip, Dialog as DialogTailwind, Alert } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { useStateContext } from '../contexts/ContextProvider';
import {MessagePreview} from '.'
import { v4 as uuidv4 } from 'uuid';
import imageThumbnail from '../img/image-thumbnail.png'
import videoThumbnail from '../img/video-thumbnail.png'
import documentThumbnail from '../img/document-thumbnail.png'
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { pdfjs, Document, Page } from 'react-pdf';
import { FaVideo } from 'react-icons/fa6';
import { FaImage } from 'react-icons/fa';
import { MdOutlineCloudUpload } from "react-icons/md";
import { IoCloseCircle, IoDocument } from "react-icons/io5";
import API from '../API/API';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const InputComponent = ({ label, onInputChange }) => {
    return (
        <div className='w-full'>
            <p className='text-xs font-semibold'>
                {label}
            </p>
            <Input
            className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
            labelProps={{
                className: "hidden",
            }}
            required
            type="text"
            label={label}
            onChange={(e) => onInputChange(e.target.value)}
            />
        </div>
    );
};
  
const ParameterSection = ({ title, content, params, onInputChange }) => {
    let shownParams
    if(content.match(/({{\d+}})/gm)?.length){
        shownParams = params
    }
    return (
        <div className='mb-5'>
            <p className='font-semibold text-xl my-2'>
                {title}
            </p>
            <p className='my-2'>{content}</p>
            {shownParams && (
                <div>
                <p className='text-sm font-bold my-2'>
                    Parameter
                </p>
                <div>
                    {shownParams.map((p, index) => (
                    <div key={index} className='flex items-center justify-start gap-2 space-y-2'>
                        <p className='font-semibold w-10'>
                        {`{{${index + 1}}}`}
                        </p>
                        <InputComponent
                        label={p}
                        onInputChange={(value) => onInputChange(index, value)}
                        />
                    </div>
                    ))}
                </div>
                </div>
            )}
        </div>
    );
};

const SendTemplate = ({socket}) => {
    const { currentUser } = useStateContext();
    const[customer, setCustomer] = useState('');
    const[customerList, setCustomerList] = useState([])
    const[template, setTemplate] = useState(null)
    const[templatesList, setTemplatesList] = useState([])
    const[inputNumber, setInputNumber] = useState([])
    const[broadcastType, setBroadcastType] = useState('')
    const[open, setOpen] = useState(false);
    const[file, setFile] = useState()
    const[fileName, setFileName] = useState('')
    const[fileUrl, setFileUrl] = useState('')

    //=====================ERROR HANDLING===================
    const [alert, setAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')

    const handleError = (message) =>{
    setAlert(true)
    setAlertMessage(message)
    }

    if(alert){
    setTimeout(() => {
        setAlert(false)
    }, 5000);
    }

    const headerParams = template?.headerParams ? JSON.parse(template.headerParams) : null;
    const bodyParams = template?.bodyParams ? JSON.parse(template.bodyParams) : null;
    const footerParams = template?.footerParams ? JSON.parse(template.footerParams) : null;

    const [headerInputValues, setHeaderInputValues] = useState(Array(headerParams?.length).fill(''));
    const [bodyInputValue, setBodyInputValue] = useState(Array(bodyParams?.length).fill(''));
    const [footerInputValues, setFooterInputValues] = useState(Array(footerParams?.length).fill(''));
    const handleHeaderInputChange = (index, value) => {
        const newHeaderInputValues = [...headerInputValues];
        newHeaderInputValues[index] = value;
        setHeaderInputValues(newHeaderInputValues);
    };
    const handleBodyInputChange = (index, value) => {
        const newBodyInputValues = [...bodyInputValue];
        newBodyInputValues[index] = value;
        setBodyInputValue(newBodyInputValues);
    };

    const handleFooterInputChange = (index, value) => {
        const newFooterInputValues = [...footerInputValues];
        newFooterInputValues[index] = value;
        setFooterInputValues(newFooterInputValues);
    };

    let params = {
        templateName: template?.id,
        language: template?.language,
        headerParams: headerInputValues,
        bodyParams: bodyInputValue,
        footerParams: footerInputValues,
        customer: customer,
        from: currentUser.phoneId,
        createdAt: new Date(),
        user:{
            id: currentUser.id,
            role: currentUser.roles,
            agent: currentUser.agent,
        },
    }

    const replacePlaceholders = (text, params, inputValues) => {
        if (!params) {
            return text;
        }

        let replacedText = text;
        params.forEach((p, index) => {
            const placeholder = `{{${index + 1}}}`;
            const inputValue = inputValues[index];

            replacedText = replacedText.split(placeholder).join(inputValue);
        });

        return replacedText;
    };

    let headerPreview
    if(template?.header.match(/({{\d+}})/gm)?.length){
        headerPreview = replacePlaceholders(template?.header, headerParams, headerInputValues);
    }else{
        switch(template?.header){
            case 'IMAGE':
                if(file){
                    headerPreview = <img alt='' src={fileUrl} className='object-cover rounded-lg w-full h-32'/>
                }else{
                    headerPreview = <img alt='' src={imageThumbnail} className='object-cover rounded-lg w-full h-32'/>
                }
                break;
            case 'VIDEO':
                if(file){
                    headerPreview = 
                    <video className='object-cover rounded-lg w-full h-32'>
                        <source src={fileUrl} type='video/mp4' controls/>
                    </video>
                }else{
                    headerPreview = <img alt='' src={videoThumbnail} className='object-cover rounded-lg w-full h-32 bg-[#EFEFEF]'/>
                }
                break;
            case 'DOCUMENT':
                if(file){
                    headerPreview = 
                        // <iframe src={fileUrl} className='object-cover rounded-lg w-60 h-32' type="application/pdf"></iframe >
                        <div className='h-32 bg-white rounded-lg p-1 w-full overflow-hidden flex justify-center'>
                            <Document
                                file={fileUrl}
                                onLoadSuccess={(p) => {}}
                            >
                                <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} width={300} devicePixelRatio={1.2}
                                onLoadError={(error) => handleError('Error while loading document! ' + error.message)}/>
                            </Document>
                        </div>
                }else{
                    headerPreview = 
                    <div className='w-full h-32 bg-[#EFEFEF] flex justify-center items-center rounded-lg'>
                        <img alt='' src={documentThumbnail} className='object-cover h-10'/>
                    </div>
                }
                break;
            default:
                headerPreview = template?.header
        }
    }

    const bodyPreview = replacePlaceholders(template?.body, bodyParams, bodyInputValue);
    const footerPreview = replacePlaceholders(template?.footer, null, footerInputValues);
    
    useEffect(() => {
        // setBroadcastType('')
        socket.emit("messageTemplates", {
            division: currentUser.division,
            type: "start conversation" 
        }, (response) =>{
            if(response){
                setTemplatesList(response); 
            }
        })
        socket.emit("getCustomerList", {},(response) =>{
            if(response){
                setCustomerList(response.data)
            }
        })
    }, [socket, currentUser]);

    const handleSelectTemplate = (value) =>{
        const data = JSON.parse(value)
        setFile('')
        setFileUrl('')
        setFileName('')
        setCustomer('')
        setInputNumber([])
        setTemplate(data)
        setHeaderInputValues(Array(headerParams?.length).fill(''))
        setBodyInputValue(Array(bodyParams?.length).fill(''))
        setFooterInputValues(Array(footerParams?.length).fill(''))
    }

    const handleSelectCustomer = (value) =>{
        const data = JSON.parse(value)
        setCustomer(data)
    }
    
    const onDelete = (numberPhone) => () => {
        setInputNumber((inputNumber) => inputNumber.filter((v) => v.numberPhone !== numberPhone));
    };

    const[openSingle, setOpenSingle] = useState(false)

    const handleOpenDialogSingle = () => setOpenSingle(!openSingle)

    const handleSendTemplate = async() =>{
        if(file){
            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileName",fileName)
            try {
                const response = await API.post('/api/upload', formData)
                if(response.status === 200){
                  const data = response.data
                  params.headerParams = {
                    link: data.url,
                    fileName: data.fileName,
                    type: headerParams.type
                  }
                //   mediaData.file.link = data.url
                //   mediaData.fileName = data.fileName
                }
            } catch (error) {
                if(error.message !== 'Network Error'){
                    handleError(error.response.data?.message)
                }else{
                    handleError('Network Error')
                }
            }
        }
        if(broadcastType === 'single'){
            console.log(params)
            try {
                socket.emit("sendMessageTemplates", params,(response) =>{
                    if(response){
                        handleOpenDialogSingle()
                    }
                })
            } catch (error) {
                handleError(error)
            }
        }
        if(broadcastType === 'multi'){
            inputNumber.forEach((p,index)=>{
                try {
                    socket.emit("sendMessageTemplates", {
                        uuid: uuidv4().replace(/-/g, ''),
                        templateName: template?.id,
                        headerParams: headerInputValues,
                        bodyParams: bodyInputValue,
                        footerParams: footerInputValues,
                        customer: p,
                        from: currentUser.phoneId,
                        createdAt: new Date(),
                        user:{
                            id: currentUser.id,
                            role: currentUser.roles,
                            agent: currentUser.agent,
                        }
                    },(response) =>{
                        if(response){
                            handleClose()
                        }
                    })
                } catch (error) {
                    handleError(error)
                }
            })
        }  
    }
    const changeBroadcastType = (e) =>{
        setBroadcastType(e)
        setCustomer('')
        setInputNumber([])
    }
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleUploadFile = (e) =>{
        try {
            setFile(e.target.files[0])
            setFileUrl(URL.createObjectURL(e.target.files[0]))
            setFileName(e.target.files[0].name)
        } catch (error) {
            handleError(error)
        }
    }
    
    return (
        <div className='h-full w-full'>
            <div className='p-5 flex md:flex-row flex-col items-end text-black gap-5'>
                <div className='md:w-[40%] w-full'>
                    <p className='text-2xl font-bold'>
                        Select Broadcast Template
                    </p>
                    <div className='w-full mt-2'>
                        <Select size='lg' label="Template" onChange={handleSelectTemplate}>
                            {templatesList.map((template) => (
                            <Option value={JSON.stringify(template)} key={template?.id}>
                                {template.name}
                            </Option>
                        ))}
                        </Select>
                    </div>
                </div>
                <div className='md:w-[40%] w-full'>
                    <p className='text-2xl font-bold'>
                        Type
                    </p>
                    <div className='w-full mt-2'>
                        <Select size='lg' label="Broadcast Type" value={broadcastType} onChange={changeBroadcastType}>
                            <Option value='single'>
                                Single
                            </Option>
                            <Option value='multi'>
                                Multiple
                            </Option>
                        </Select>
                    </div>
                </div>
                <div className='flex mt-10 justify-end md:w-[20%] w-full'>
                    <Button onClick={broadcastType === 'single' ? handleOpenDialogSingle : handleOpen} disabled={!broadcastType}>
                        Add Recipient
                    </Button>
                </div>
            </div>
            <div className='p-5'>
            {template && (
                <>
                    <div className='flex md:flex-row flex-col gap-5'>
                        <Card className='p-5 md:w-1/2 w-full text-black'>
                            <CardBody className='p-0'>
                                <Typography variant='h5'>
                                    Template
                                </Typography>
                                <hr className='h-0.5 border-0 rounded bg-gray-700 my-5'/>
                                {template?.header === 'IMAGE' || template?.header === 'VIDEO' || template?.header === 'DOCUMENT' ? 
                                <div className='bg-gray-200 p-2 mt-5 rounded-md'>
                                    <p className='text-lg font-semibold'>
                                        Header content
                                    </p>
                                    <p className='text-sm text-gray-700'>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.</p>
                                    <div>
                                        <div className='flex items-center justify-start gap-3 mt-2'>
                                            <p className='capitalize'>
                                                {template?.header.toLocaleLowerCase()}
                                            </p>
                                            <div>
                                                {!file ? (
                                                    <div>
                                                        <input id="file_input" className='hidden' type='file' accept={template?.header === 'IMAGE' ? '.png, .jpg': template?.header === 'VIDEO'? '.mp4' : template?.header === 'DOCUMENT' ? '.pdf' :''} 
                                                        onChange={handleUploadFile}/>
                                                        <label htmlFor='file_input' className='flex items-center text-white bg-gray-900 rounded-lg px-2 py-3 justify-center gap-2 text-xs font-semibold uppercase hover:drop-shadow-xl cursor-pointer hover:bg-opacity-90 transition-all'>
                                                            <MdOutlineCloudUpload className='text-xl'/>
                                                            {template?.header === 'IMAGE' ? 'Choose JPG or PNG File': template?.header === 'VIDEO'? 'Choose MP4 File' : template?.header === 'DOCUMENT' ? 'Choose PDF File' :''}
                                                        </label>
                                                    </div>
                                                ):(
                                                    <div>
                                                        <div className='flex items-center text-white bg-gray-900 rounded-lg px-2 py-3 justify-center gap-2 text-xs  hover:drop-shadow-xl cursor-pointer hover:bg-opacity-90 transition-all'>
                                                            <div className='max-w-5 max-h-5 overflow-hidden'>
                                                            {template?.header === 'IMAGE' ? 
                                                            <FaImage/>
                                                            : template?.header === 'VIDEO'? 
                                                            <FaVideo/>
                                                            : template?.header === 'DOCUMENT' ? 
                                                            <IoDocument/>
                                                            :''}
                                                            </div>
                                                            {fileName}
                                                            <button className='p-1 hover:bg-white hover:text-black hover:rounded-full transition-all'
                                                            onClick={() =>{
                                                                try {
                                                                    setFile('')
                                                                    setFileName('')
                                                                    setFileUrl('')
                                                                } catch (error) {
                                                                    handleError(error)
                                                                }
                                                            }}
                                                            >
                                                                <IoCloseCircle />
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <ParameterSection
                                    title="Header"
                                    content={template?.header}
                                    params={headerParams}
                                    onInputChange={handleHeaderInputChange}
                                />
                                }
                                <ParameterSection
                                    title="Body"
                                    content={template?.body}
                                    params={bodyParams}
                                    onInputChange={handleBodyInputChange}
                                />
                                <ParameterSection
                                    title="Footer"
                                    content={template?.footer}
                                    params={footerParams}
                                    onInputChange={handleFooterInputChange}
                                />
                            </CardBody>
                        </Card>
                        <MessagePreview size={'md:w-1/2'} headerPreview={headerPreview} bodyPreview={bodyPreview}
                        footerPreview={footerPreview} />
                    </div>
                </>
            )}
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    backdropFilter: "blur(5px)",
                  }}
                  PaperProps={{ sx: { borderRadius: "20px", boxShadow: "none", 
                  width: '100%'} }}          
            >
                <DialogTitle>
                    <p className='text-2xl font-bold text-gray-700'>
                        Select Customer
                    </p>
                </DialogTitle>
                <DialogContent>
                    <div className='flex justify-center items-center shadow-none'>
                        <div>
                            <p className='font-semibold text-sm'>
                            Select Customer
                            </p>
                            <Autocomplete
                                sx={{ width: '500px', maxHeight: '48px'}}
                                multiple
                                filterSelectedOptions
                                options={customerList}
                                getOptionLabel={(option) => `${option.name} - ${option.numberPhone}`}
                                value={inputNumber}
                                renderOption={(props, option) => (
                                <div {...props} className='px-3 py-2 rounded-lg text-gray-800 hover:bg-gray-100 cursor-pointer'>
                                    <li className='text-sm'>{option.name} - {option.numberPhone}</li>
                                </div>
                                )}
                                PaperComponent={({ children }) => {
                                return (
                                    <div className='transition-all ease-in-out p-2 mt-2 rounded-lg !border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10 drop-shadow-lg'>
                                    {children}
                                    </div>
                                );
                                }}
                            
                                onChange={(e, newValue) => setInputNumber(newValue)}
                                renderTags={() => null}
                                renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <Input {...params.inputProps} autoFocus 
                                    className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                                    labelProps={{
                                    className: "hidden",
                                    }}
                                    containerProps={{ className: "min-w-[100px]" }}/>
                                </div>
                                )}
                            />
                            <div className='mt-3 max-w-[500px] flex flex-wrap gap-1'>
                                {inputNumber?.length > 0 && inputNumber.map((v) =>(
                                    <Chip className='mt-1' key={v.numberPhone} value={`${v.name} - ${v.numberPhone}`} onClose={onDelete(v.numberPhone)}/>
                                ))}
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSendTemplate} disabled={!template}>
                        Send Template
                    </Button>
                </DialogActions>
            </Dialog>
            <DialogTailwind
            size="md"
            open={openSingle}
            handler={handleOpenDialogSingle}
            className="bg-transparent shadow-none"
            >
            <Card>
                <CardBody>
                    <div className='space-y-5'>
                        <div>
                            <p className='text-2xl font-bold'>
                                Send to
                            </p>
                        </div>
                        <div className='mt-5 md:mt-0'>
                            <div className='mt-2'>
                                <Select size='lg' label="Customer" onChange={handleSelectCustomer}>
                                    {customerList.map((customer) => (
                                    <Option value={JSON.stringify(customer)} key={customer?.id}>
                                        {customer.name} - {customer.numberPhone}
                                    </Option>
                                ))}
                                </Select>
                            </div>
                        </div>
                        <div className='w-full flex justify-end'>
                            <Button onClick={handleSendTemplate} disabled={!template}>
                                Send Template
                            </Button>
                        </div>
                    </div>
                </CardBody>
            </Card>
            </DialogTailwind>
            <div className='absolute md:right-5 z-10 bottom-5 p-5 md:w-[21%] w-full'>
                <Alert open={alert} color="red">
                <p className='font-bold'>
                    Error
                </p>
                <p>
                    Message : {alertMessage}
                </p>
                </Alert>
            </div>
        </div>
    )
}

export default SendTemplate