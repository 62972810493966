import React, { useEffect, useState } from 'react'
import { IoIosChatboxes, IoMdContacts } from "react-icons/io";
import { TbUserExclamation } from "react-icons/tb";
import { IoCheckmarkDone, IoLogoWhatsapp  } from "react-icons/io5";
import { BiMailSend } from "react-icons/bi";
import AreaChart from '../components/AreaChart';
import { RiCustomerService2Fill, RiContactsBook2Fill } from "react-icons/ri";
import AgentList from '../components/AgentList';
import { AgentConversationList, Loading } from '../components';
import API from '../API/API';
import { Alert } from '@material-tailwind/react';
import useDocumentTitle from '../useDocumentTitle';

const Card = ({icon, title, data, size}) =>{
  return(
    <div className={`md:${size} w-full min-h-40 rounded-lg shadow-md bg-white p-5 flex justify-center items-center flex-col`}>
      <div className='flex gap-2 items-center'>
        {icon}
        <p className='font-semibold'>
          {title}
        </p>
      </div>
      <p className='text-5xl font-bold'>
        {data}
      </p>
    </div>
  )
}


const Home = () => {
  useDocumentTitle('Dashboard - ChatApp')
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [chartData, setChartData] = useState([])
  //=====================ERROR HANDLING===================
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const handleError = (message) =>{
    setAlert(true)
    setAlertMessage(message)
  }

  if(alert){
    setTimeout(() => {
    setAlert(false)
    }, 5000);
  }

  useEffect(() => {
    API.get('/api/dashboard').then((response) => {
      if(response.status === 200){
        setData(response.data.data[0])
        setChartData(response.data.chartData)
      }
    }).catch((err) =>{
      if(err.message !== 'Network Error'){
        handleError(err.response.data?.message)
      }else{
        handleError('Network Error')
      }
    }).finally(
      setLoading(false)
    )
  }, []);
  if(loading){
    return <Loading/>
  }
  return (
    <div className='w-full h-full space-y-5 p-8 text-gray-900'>
      <div className='w-full'>
        <p className='text-3xl font-bold'>
          Dashboard
        </p>
      </div>
      <div className='space-y-5 bg-gray-100 rounded-t-lg p-5 mb-10'>
        <div className='w-full rounded-lg shadow-md bg-green-500 text-white p-5'>
          <div className='flex gap-2 items-center'>
            <IoIosChatboxes className='text-xl'/>
            <p className='font-bold text-2xl'>
              Conversation
            </p>
          </div>
        </div>
        <div className='w-full flex md:flex-row flex-col gap-5'>
          <Card size={'w-[25%]'} icon={<IoIosChatboxes className='text-xl'/>} title='Total Conversation' data={data.totalConversation} />
          <Card size={'w-[25%]'} icon={<TbUserExclamation className='text-xl'/>} title='Unserved Conversation' data={data.unservedConversation} />
          <Card size={'w-[25%]'} icon={<BiMailSend className='text-xl'/>} title='Served Conversation' data={data.servedConversation} />
          <Card size={'w-[25%]'} icon={<IoCheckmarkDone className='text-xl'/>} title='Resolved Conversation' data={data.resolvedConversation} />
        </div>
        <div className='w-full flex md:flex-row flex-col gap-5'>
          <div className='w-full min-h-60 rounded-lg shadow-md bg-white p-5 flex justify-center items-center flex-col'>
           <AreaChart data={chartData}/>
          </div>
        </div>
        <div className='w-full rounded-lg shadow-md bg-green-500 text-white p-5'>
          <div className='flex gap-2 items-center'>
            <RiCustomerService2Fill className='text-xl'/>
            <p className='font-bold text-2xl'>
              Agents
            </p>
          </div>
        </div>
        <div className='w-full flex md:flex-row flex-col gap-5'>
          <div className='md:w-[75%] w-full min-h-40 rounded-lg shadow-md bg-white p-5 flex justify-center items-center flex-col'>
            <AgentList mode={'home'}/>
          </div>
          <div className='md:w-[25%] w-full space-y-5'>
            <Card icon={<RiCustomerService2Fill className='text-xl'/>} title='Total Agent' data={data.totalAgent} />
            <div className='w-full min-h-40 rounded-lg shadow-md bg-white p-5 flex justify-center items-center flex-col'>
              <AgentConversationList/>
            </div>
          </div>
        </div>
        <div className='w-full rounded-lg shadow-md bg-green-500 text-white p-5'>
          <div className='flex gap-2 items-center'>
            <IoMdContacts className='text-xl'/>
            <p className='font-bold text-2xl'>
              Customers
            </p>
          </div>
        </div>
        <div className='w-full flex md:flex-row flex-col gap-5 justify-center'>
          <Card size={'w-[33%]'} icon={<IoMdContacts className='text-xl'/>} title='Total Customers' data={data.totalCustomers} />
          <Card size={'w-[33%]'} icon={<IoLogoWhatsapp className='text-xl'/>} title='Customer from Whatsapp' data={data.totalOrganicCustomers} />
          <Card size={'w-[33%]'} icon={<RiContactsBook2Fill className='text-xl'/>} title='Customer Added By Admin' data={data.totalAddedCustomer} />
        </div>
      </div>
      <div className='absolute md:right-5 z-10 bottom-5 p-5 md:w-[21%] w-full'>
        <Alert open={alert} color="red">
          <p className='font-bold'>
            Error
          </p>
          <p>
            Message : {alertMessage}
          </p>
        </Alert>
      </div>
    </div>
  )
}

export default Home