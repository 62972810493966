import React, { useEffect, useRef, useState } from 'react'
import {Login, Register} from '.'
import logo from '../img/logo-chat-gw.png'
import illustation from '../img/Colored Live Chat Illustration.png'

const tabsData = [
  {
    label: "Login",
    content: <Login/>
  },
  {
    label: "Register",
    content: <Register/>
  },
];


const SignIn = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  // const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  // const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  const tabsRef = useRef([]);

  useEffect(() => {
    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex];
      console.log(currentTab?.offsetLeft, currentTab?.clientWidth);
      // setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      // setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }
    setTabPosition();
    window.addEventListener("resize", setTabPosition);
    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);

  return (
    <div className='flex w-full h-screen bg-green-500'>
      <div className='lg:w-[80%] hidden lg:block text-white h-full p-10 overflow-hidden'>
        <div className='flex gap-2 h-[10%]'>
          <img src={logo} alt="logo" className='h-16' />
          <div>
            <p className='font-bold text-3xl'>
              ChatApp
            </p>
            <p className='text-xs'>
              By EnterKomputer
            </p>
          </div>
        </div>
        <div className='drop-shadow-md'>
          <p className='font-bold text-6xl'>
            Welcome back!
          </p>
          <div className='flex gap-1 font-semibold'>
            <button role="link" onClick={() => setActiveTabIndex(0)} className="relative after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 after:bg-white after:rounded-full after:space-y-1 after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65_0.05_0.36_1)] hover:after:origin-bottom-left hover:after:scale-x-100">Sign In</button>
            <p>
              to continue
            </p>
            {/* <p className='hover:text-gray-600 cursor-pointer' onClick={() => setActiveTabIndex(1)}>
              Register
            </p> */}
          </div>
        </div>
        <div className='h-[90%] ml-10 w-full drop-shadow-2xl'>
          <img src={illustation} alt="" className='w-[90%]' />
        </div>
      </div>
      <div className='lg:w-[50%] w-full space-y-5 lg:bg-gray-50 lg:rounded-l-[50%] rounded-none'>
        <div className='h-full w-full'>
          <div className='flex gap-2 h-[20%] text-white justify-center lg:hidden items-end'>
            <img src={logo} alt="logo" className='h-16' />
            <div>
              <p className='font-bold text-3xl'>
                ChatApp
              </p>
              <p className='text-xs'>
                By EnterKomputer
              </p>
            </div>
          </div>
          {/* <div className="flex-row relative mx-auto flex justify-center p-1 h-[5%] mt-5 lg:w-[300px] w-full max-w-[300px] rounded-lg border bg-white shadow-lg backdrop-blur-sm">
            <span
              className="absolute bottom-0 top-0 -z-10 flex overflow-hidden rounded-lg p-1 transition-all duration-300"
              style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
            >
              <span className="h-full w-full rounded-lg bg-gray-800 shadow-xl" />
            </span>
            {tabsData.map((tab, idx) => {
              const isActive = activeTabIndex === idx;
              return (
                <button
                  key={idx}
                  ref={(el) => (tabsRef.current[idx] = el)}
                  className={`transition-all ${
                    isActive ? `font-bold text-white` : `text-gray-800`
                  } my-auto cursor-pointer select-none rounded-lg px-4 text-center  w-[50%]`}
                  onClick={() => setActiveTabIndex(idx)}>
                  {tab.label}
                </button>
              );
            })}
          </div> */}
          <div className='w-full h-[80%] flex justify-center lg:items-center items-start'>
            <div className="py-4 w-full">
              {tabsData[activeTabIndex].content}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignIn 