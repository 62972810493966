import React from 'react'

const Loading = () => {
  return (
    <div className='h-[90vh] w-full flex justify-center items-center text-5xl font-bold'>
        <p className=' animate-bounce [animation-delay:-1.0s]'>L</p>
        <p className=' animate-bounce [animation-delay:-0.9s]'>O</p>
        <p className=' animate-bounce [animation-delay:-0.8s]'>A</p>
        <p className=' animate-bounce [animation-delay:-0.7s]'>D</p>
        <p className=' animate-bounce [animation-delay:-0.6s]'>I</p>
        <p className=' animate-bounce [animation-delay:-0.5s]'>N</p>
        <p className=' animate-bounce [animation-delay:-0.4s]'>G</p>
        <p className=' animate-bounce [animation-delay:-0.3s]'>.</p>
        <p className=' animate-bounce [animation-delay:-0.2s]'>.</p>
        <p className=' animate-bounce [animation-delay:-0.1s]'>.</p>
    </div>
  )
}

export default Loading