import { Alert, Button, Card, CardBody, Input, Option, Select, Textarea, Typography } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { IoMdInformationCircle } from "react-icons/io";
import { ImFilePicture, ImFileText2, ImFileVideo } from "react-icons/im";
import { MdOutlineCloudUpload } from "react-icons/md";
import imageThumbnail from '../img/image-thumbnail.png'
import videoThumbnail from '../img/video-thumbnail.png'
import documentThumbnail from '../img/document-thumbnail.png'
import { IoCloseCircle, IoDocument } from "react-icons/io5";
import { MessagePreview } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import languageList from '../data/templateLang.json'
import { pdfjs, Document, Page } from 'react-pdf';
import { FaVideo } from 'react-icons/fa6';
import { FaImage } from 'react-icons/fa';
import API from '../API/API';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const InputComponent = ({ label, onInputChange }) => {
    return (
        <Input
        className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
        labelProps={{
            className: "hidden",
        }}
        required
        type="text"
        label={label}
        onChange={(e) => onInputChange(e.target.value)}
        />
    );
};
const ParameterSection = ({ title, content, params, onInputChange }) => {
    return (
        <div className='bg-gray-200 p-2 mt-5 rounded-md'>
            <p className='text-lg font-semibold'>
                {title}
            </p>
            <p className='text-sm text-gray-700'>{content}</p>
            {params && (
                <div>
                    {params.map((p, index) => (
                    <div key={index} className='flex items-center justify-start gap-2 my-2'>
                        <p className='font-semibold w-10'>
                        {`{{${index + 1}}}`}
                        </p>
                        <InputComponent
                        label={`Enter content for {{${index + 1}}}`}
                        onInputChange={(value) => onInputChange(index, value)}
                        />
                    </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const CreateTemplate = ({socket}) => {
    const{currentUser} = useStateContext();
    const[name, setName] = useState('')
    const[category, setCategory] = useState('')
    const[language, setLanguage] = useState('')
    const[headerFormat, setHeaderFormat] = useState('')
    const[headerMediaFormat, setHeaderMediaFormat] = useState('')
    const[headerText, setHeaderText] = useState('')
    const[headerParams, setHeaderParams] = useState([])
    const[bodyParams, setBodyParams] = useState([])
    const[bodyText, setBodyText] = useState('')
    const[footerText, setFooterText] = useState('')
    const[headerHandle, setHeaderHandle] = useState('')
    const[isHeaderVar, setIsHeaderVar] = useState(false)
    const[isBodyVar, setIsBodyVar] = useState(false)
    const[file, setFile] = useState()
    const[fileName, setFileName] = useState('')
    const[fileUrl, setFileUrl] = useState('')

    //=====================ERROR HANDLING===================
    const [alert, setAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')

    const handleError = (message) =>{
    setAlert(true)
    setAlertMessage(message)
    }

    if(alert){
    setTimeout(() => {
        setAlert(false)
    }, 5000);
    }

    let data = {
        currentUser: currentUser,
        name: name,
        language: language,
        category: category,
        components: [
            {
                type: "HEADER",
                format: headerFormat,
                text: headerText,
                example: {
                    header_text: headerParams
                }
            },
            {
                type: "BODY",
                text: bodyText,
                example: {
                    body_text: [bodyParams]
                }
            },
            {
                type: "FOOTER",
                text: footerText
            }
        ]
    }

    const replacePlaceholders = (text, params, component) => {
        if(component === 'header'){
            if(headerFormat === 'TEXT'){
                if (params[0] === '') {
                    return text;
                }
                let replacedText = text;

                params.forEach((p, index) => {
                    const placeholder = `{{${index + 1}}}`;
                    const inputValue = params[index];
        
                    replacedText = replacedText.split(placeholder).join(inputValue);
                });
        
                return replacedText;
            }else{
                let thumbnail = ''
                switch(headerMediaFormat){
                    case 'IMAGE':
                        if(file){
                            thumbnail = <img alt='' src={fileUrl} className='object-cover rounded-lg w-60 h-32'/>
                        }else{
                            thumbnail = <img alt='' src={imageThumbnail} className='object-cover rounded-lg w-60 h-32'/>
                        }
                        break;
                    case 'VIDEO':
                        if(file){
                            thumbnail = 
                            <video className='object-cover rounded-lg w-60 h-32'>
                                <source src={fileUrl} type='video/mp4' controls/>
                            </video>
                        }else{
                            thumbnail = <img alt='' src={videoThumbnail} className='object-cover rounded-lg w-60 h-32 bg-[#EFEFEF]'/>
                        }
                        break;
                    case 'DOCUMENT':
                        if(file){
                            thumbnail = 
                                // <iframe src={fileUrl} className='object-cover rounded-lg w-60 h-32' type="application/pdf"></iframe >
                                <div className='h-32 bg-white rounded-lg p-1 overflow-hidden'>
                                    <Document
                                        file={fileUrl}
                                        onLoadSuccess={(p) => {}}
                                    >
                                        <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} width={300} devicePixelRatio={1.2}
                                        onLoadError={(error) => handleError('Error while loading document! ' + error.message)}/>
                                    </Document>
                                </div>
                        }else{
                            thumbnail = 
                            <div className='w-full min-w-60 h-32 bg-[#EFEFEF] flex justify-center items-center rounded-lg'>
                                <img alt='' src={documentThumbnail} className='object-cover h-10'/>
                            </div>
                        }
                        break;
                    default:
                        break;
                }
                return thumbnail;
            }
        }else if(component === 'body'){
            if (params[0] === '') {
                return text;
            }
            let replacedText = text;

            params.forEach((p, index) => {
                const placeholder = `{{${index + 1}}}`;
                const inputValue = params[index];
    
                replacedText = replacedText.split(placeholder).join(inputValue);
            });
    
            return replacedText;
        } 
    };
    const handleHeaderFormatChange = (value) =>{
        setFile('')
        setFileName('')
        if(value === 'MEDIA' || value === 'TEXT' || value === 'NONE'){
            setHeaderFormat(value)
            setHeaderMediaFormat('')
        }else{
            setHeaderMediaFormat(value)
        }
    }

    const headerIndex = data.components.findIndex(component => component.type === "HEADER");
    const newHeaderComponent = (headerFormat === "TEXT")
        ? {
            type: "HEADER",
            format: headerFormat,
            text: headerText,
            example: {
                header_text: headerParams
            }
            }
        : {
            type: "HEADER",
            format: headerMediaFormat,
            example: {
                header_handle: [headerHandle]
            }
        };
    data.components[headerIndex] = newHeaderComponent;

    const headerPreview = replacePlaceholders(headerText, headerParams, 'header');
    const bodyPreview = replacePlaceholders(bodyText, bodyParams,'body');
    
    
    const handleHeaderInputChange = (index, value) => {
        const newHeaderInputValues = [...headerParams];
        newHeaderInputValues[index] = value;
        setHeaderParams(newHeaderInputValues);
    };
    const handleBodyInputChange = (index, value) => {
        const newBodyInputValues = [...bodyParams];
        newBodyInputValues[index] = value;
        setBodyParams(newBodyInputValues);
    };

    const handleAddHeaderVariable = () =>{
        if(!headerText?.match(/({{\d+}})/gm)?.length){
            setIsHeaderVar(true)
            setHeaderText(headerText+`{{1}}`)
            setHeaderParams(Array(1).fill(''))
        }
    }

    const handleAddBodyVariable = () =>{
        if(!bodyText?.match(/({{\d+}})/gm)?.length){
            setIsBodyVar(true)
            setBodyText(bodyText+`{{1}}`)
            setBodyParams(Array(1).fill(''))
        }else{
            const index = bodyText?.match(/({{\d+}})/gm)?.length
            setIsBodyVar(true)
            setBodyText(bodyText+`{{${index+1}}}`)
            setBodyParams([...bodyParams,''])
        }
    }

    useEffect(() => {
        let isValid = {
            header: true,
            body: true,
            footer: true
        }
        //header
        const countHeader = headerText?.match(/({{\d+}})/gm)
        if(countHeader?.length === 1){
            isValid.header = false
        }
        if(!countHeader?.length || countHeader?.length > 1){
            setIsHeaderVar(false)
            isValid.header = true
            setHeaderParams(Array(countHeader?.length).fill(''))
        }else{
            setIsHeaderVar(true)
            setHeaderParams(Array(countHeader?.length).fill(''))
            isValid.header = false
        }
        if(headerFormat === 'TEXT'){
            setHeaderMediaFormat('')
        }else{
            setHeaderText('')
        }
        //body =============================== UPDATE DISINI =================================================
        const countBody = bodyText?.match(/({{\d+}})/gm)
        if(countBody?.length < 1 || !countBody?.length){
            setIsBodyVar(false)
            isValid.body = true
            setBodyParams(Array(countBody?.length).fill([]))
        }
        if(countBody?.length > 0){
            setIsBodyVar(true)
            setBodyParams(bodyParams => bodyParams.filter((s,i) => (i !== countBody?.length)))
            isValid.body = false
        }
    }, [headerText, headerFormat, bodyText]);



    const handleSubmitTemplate = async() =>{
        if(file){
            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileName",fileName)
            try {
                const uploadLocal = await API.post('/api/upload', formData)
                if(uploadLocal.status === 200){
                    const uploadLocalData = uploadLocal.data
                    const uploadMeta = await API.post('/api/template/uploadMedia', formData)
                    if(uploadMeta.status === 200){
                        const uploadMetaData = uploadMeta.data
                        setHeaderHandle(uploadMetaData.body.h)
                        data.file = {
                            type: headerMediaFormat.toLowerCase(),
                            fileName: uploadLocalData.fileName,
                            link: uploadLocalData.url
                        }
                    }
                }
            } catch (error) {
                if(error.message !== 'Network Error'){
                  handleError(error.response.data?.message)
                }else{
                  handleError('Network Error')
                }
            }
        }
        try {
            socket.emit("createTemplates", data,(response) =>{
                // if(response){
                //     console.log(response)
                // }
            })
        } catch (error) {
            handleError(error)
        }
    }

    const handleUploadFile = (e) =>{
        try {
            setFile(e.target.files[0])
            setFileUrl(URL.createObjectURL(e.target.files[0]))
            setFileName(e.target.files[0].name)
        } catch (error) {
            handleError(error)
        }
    }

  return (
    <div className='p-5 overflow-y-scroll h-[90vh]'>
        <Card className='bg-white text-black' shadow={false}>
            <CardBody>
                <Typography variant='h4' >
                    Create New Template 
                </Typography>
                <div className='flex md:flex-row flex-col md:justify-between justify-center mt-10 items-center w-full gap-10'>
                    <div className='w-full'>
                        <div className='flex flex-col md:flex-row'>
                            <p className='font-semibold md:w-[15%] w-full'>
                                Template Name
                            </p>
                            <div className='mt-2 md:mt-0 w-full'>
                                <Input
                                className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                                labelProps={{
                                    className: "hidden",
                                }}
                                label='Name' value={name} onChange={e => setName(e.target.value)}/>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row mt-5'>
                            <p className='font-semibold md:w-[15%] w-full'>
                                Template Category
                            </p>
                            <div className='mt-2 md:mt-0 w-full'>
                                <Select
                                className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                                labelProps={{
                                    className: "hidden",
                                }}
                                value={category} onChange={setCategory} label='Select Category'>
                                    <Option value='AUTHENTICATION'>
                                        Authentication
                                    </Option>
                                    <Option value='MARKETING'>
                                        Marketing
                                    </Option>
                                    <Option value='UTILITY'>
                                        Utility
                                    </Option>
                                </Select>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row mt-5'>
                            <p className='font-semibold md:w-[15%] w-full'>
                                Template Language
                            </p>
                            <div className='mt-2 md:mt-0 w-full'>
                                <Select
                                className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                                labelProps={{
                                    className: "hidden",
                                }}
                                value={language} onChange={setLanguage}>
                                    {languageList.map((lang, index) =>(
                                        <Option value={lang.code} key={index}>
                                            {lang.code} - {lang.name}
                                        </Option>
                                    )
                                    )}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <Button className='h-24 w-24' onClick={handleSubmitTemplate}
                    disabled={
                        !name || !category || !bodyText || !language
                    }>
                        Submit
                    </Button>
                </div>
            </CardBody>
        </Card>
        <div className='flex md:flex-row flex-col gap-5 mb-5'>
            <div className='md:w-10/12 w-full'>
                <Card className='p-5 text-black' shadow={false}>
                    <CardBody>
                        <div className=''>
                            <Typography variant='h5'>
                                Header
                            </Typography>
                            <small className='text-gray-700'>
                                Add a title or choose which type of media you'll use for this header.
                            </small>
                            <div className='w-full flex flex-col md:flex-row mt-2 gap-10'>
                                <div className='md:w-1/6 w-full'>
                                    <Select 
                                    className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                                    labelProps={{
                                        className: "hidden",
                                    }}
                                    label='Type' value={headerFormat} onChange={handleHeaderFormatChange}>
                                        <Option value='TEXT'>
                                            Text
                                        </Option>
                                        <Option value='MEDIA'>
                                            Media
                                        </Option>
                                        <Option value='NONE'>
                                            None
                                        </Option>
                                    </Select>
                                </div>
                                <div className='md:w-5/6 w-full md:ml-5 ml-0 mt-2 md:mt-0'>
                                    {headerFormat === 'TEXT' ? (
                                        <>
                                            <Input
                                            className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                                            labelProps={{
                                                className: "hidden",
                                            }}
                                            type='text' value={headerText} required onChange={(e) => setHeaderText(e.target.value)} label='Header Text' maxLength={60}/>
                                            <Typography
                                                variant="small"
                                                className="mt-2 flex items-center gap-1 font-normal text-gray-700"
                                            >
                                                <IoMdInformationCircle/>
                                                Maximum 60 Character ({headerText.length}/60)
                                            </Typography>
                                            <div className='flex justify-end'>
                                                <Button size='sm' onClick={handleAddHeaderVariable} disabled={headerText?.match(/({{\d+}})/gm)?.length === 1 ? true : headerText?.length >= 60 ? true : false} >
                                                    Add Variable
                                                </Button>
                                            </div>
                                            {isHeaderVar && (
                                                <ParameterSection
                                                title='Samples for header content'
                                                content='To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services'
                                                params={headerParams}
                                                onInputChange={handleHeaderInputChange}
                                                />
                                            )}
                                        </>
                                    ): headerFormat === 'MEDIA' ? (
                                        <div>
                                            <Select
                                            className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                                            labelProps={{
                                                className: "hidden",
                                            }}
                                            size='lg' label='Select Media Type' onChange={handleHeaderFormatChange}
                                            selected={(element) =>
                                                element &&
                                                React.cloneElement(element, {
                                                disabled: true,
                                                className:
                                                    "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                                            })}
                                            >
                                                <Option value='IMAGE' className="flex items-center gap-2">
                                                    <ImFilePicture className='h-5 w-5 object-cover'/>
                                                    Image  
                                                </Option>
                                                <Option value='VIDEO' className="flex items-center gap-2">
                                                    <ImFileVideo className='h-5 w-5 object-cover'/>
                                                    Video  
                                                </Option>
                                                <Option value='DOCUMENT' className="flex items-center gap-2">
                                                    <ImFileText2 className='h-5 w-5 object-cover'/>
                                                    Document  
                                                </Option>
                                            </Select>
                                            <Typography
                                                variant="small"
                                                className="mt-2 flex items-center gap-1 font-normal text-gray-700"
                                            >
                                                <IoMdInformationCircle/>
                                                Choose the type of MEDIA Header
                                            </Typography>
                                            {headerMediaFormat &&(
                                                <div className='bg-gray-200 p-2 mt-5 rounded-md'>
                                                    <p className='text-lg font-semibold'>
                                                        Samples for header content
                                                    </p>
                                                    <p className='text-sm text-gray-700'>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.</p>
                                                    <div>
                                                        <div className='flex items-center justify-start gap-3 mt-2'>
                                                            <p className='capitalize'>
                                                                {headerMediaFormat.toLocaleLowerCase()}
                                                            </p>
                                                            <div>
                                                                {!file ? (
                                                                    <div>
                                                                        <input id="file_input" className='hidden' type='file' accept={headerMediaFormat === 'IMAGE' ? '.png, .jpg': headerMediaFormat === 'VIDEO'? '.mp4' : headerMediaFormat === 'DOCUMENT' ? '.pdf' :''} 
                                                                        onChange={handleUploadFile}/>
                                                                        <label htmlFor='file_input' className='flex items-center text-white bg-gray-900 rounded-lg px-2 py-3 justify-center gap-2 text-xs font-semibold uppercase hover:drop-shadow-xl cursor-pointer hover:bg-opacity-90 transition-all'>
                                                                            <MdOutlineCloudUpload className='text-xl'/>
                                                                            {headerMediaFormat === 'IMAGE' ? 'Choose JPG or PNG File': headerMediaFormat === 'VIDEO'? 'Choose MP4 File' : headerMediaFormat === 'DOCUMENT' ? 'Choose PDF File' :''}
                                                                        </label>
                                                                    </div>
                                                                ):(
                                                                    <div>
                                                                        <div className='flex items-center text-white bg-gray-900 rounded-lg px-2 py-3 justify-center gap-2 text-xs  hover:drop-shadow-xl cursor-pointer hover:bg-opacity-90 transition-all'>
                                                                            <div className='max-w-5 max-h-5 overflow-hidden'>
                                                                            {headerMediaFormat === 'IMAGE' ? 
                                                                            <FaImage/>
                                                                            : headerMediaFormat === 'VIDEO'? 
                                                                            <FaVideo/>
                                                                            : headerMediaFormat === 'DOCUMENT' ? 
                                                                            <IoDocument/>
                                                                            :''}
                                                                            </div>
                                                                            {fileName}
                                                                            <button className='p-1 hover:bg-white hover:text-black hover:rounded-full transition-all'
                                                                            onClick={() =>{
                                                                                try {
                                                                                    setFile('')
                                                                                    setFileName('')
                                                                                    setFileUrl('')
                                                                                } catch (error) {
                                                                                    handleError(error)
                                                                                }
                                                                            }}
                                                                            >
                                                                                <IoCloseCircle />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ):''}
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <Typography variant='h5'>
                                Body
                            </Typography>
                            <small className='text-gray-700'>
                                Enter the text for your message in the language you've selected.
                            </small>
                            <div className='w-full mt-2 gap-5'>
                                <div className='w-full'>
                                    <Textarea
                                    className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                                    labelProps={{
                                        className: "hidden",
                                    }}
                                    label="Body" onChange={e =>{setBodyText(e.target.value)}} value={bodyText} maxLength={1024} />
                                    <Typography
                                        variant="small"
                                        className="mt-2 flex items-center gap-1 font-normal text-gray-700"
                                    >
                                        <IoMdInformationCircle/>
                                        Maximum 1024 Character ({bodyText.length}/1024)
                                    </Typography>
                                </div>
                                <div className='flex justify-end'>
                                    <Button size='sm' onClick={handleAddBodyVariable} disabled={bodyText.length >= 1024}>
                                        Add Variable
                                    </Button>
                                </div>
                                {isBodyVar && (
                                    <ParameterSection
                                    title='Samples for body content'
                                    content='To help us review your message template, please add an example for each variable in your body text. Do not use real customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.'
                                    params={bodyParams}
                                    onInputChange={handleBodyInputChange}
                                    />
                                )}
                            </div>
                        </div>
                        <div className='mt-5'>
                            <Typography variant='h5'>
                                Footer
                            </Typography>
                            <small className='text-gray-700'>
                                Add a short line of text to the bottom of your message template.
                            </small>
                            <div className='w-full mt-2 gap-5'>
                                <div className='w-full'>
                                    <Input 
                                    className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                                    labelProps={{
                                        className: "hidden",
                                    }}
                                    type='text' value={footerText} required onChange={(e) => setFooterText(e.target.value)} label='Footer Text' maxLength={60}/>
                                    <Typography
                                        variant="small"
                                        className="mt-2 flex items-center gap-1 font-normal text-gray-700"
                                    >
                                        <IoMdInformationCircle/>
                                        Maximum 60 Character ({footerText.length}/60)
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
            <MessagePreview headerPreview={headerPreview} bodyPreview={bodyPreview}
            footerPreview={footerText} headerFormat={headerFormat}
            />
        </div>
        <div className='absolute md:right-5 z-10 bottom-5 p-5 md:w-[21%] w-full'>
            <Alert open={alert} color="red">
            <p className='font-bold'>
                Error
            </p>
            <p>
                Message : {alertMessage}
            </p>
            </Alert>
        </div>
    </div>
  )
}

export default CreateTemplate