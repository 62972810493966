import React, { useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Alert,
} from "@material-tailwind/react";
import logo from '../img/logo-chat-gw.png'
import { IoMdChatbubbles, IoMdContacts, IoMdSettings } from "react-icons/io";
import { TbLogout } from "react-icons/tb";
import { Link } from "react-router-dom";
import API from "../API/API";
import { useNavigate } from 'react-router-dom';
import { useStateContext } from "../contexts/ContextProvider";
import { BiSolidMessageEdit } from "react-icons/bi";

const NavBar = () => {
  const { setLoggedIn, setCurrentUser } = useStateContext();
  const navigate = useNavigate()
  //=====================ERROR HANDLING===================
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const handleError = (message) =>{
    setAlert(true)
    setAlertMessage(message)
  }

  if(alert){
    setTimeout(() => {
    setAlert(false)
    }, 5000);
  }
  const Logout = () => {
    try {
      API.post('/api/auth/signout')
      navigate('/')
      setCurrentUser(null);
      setLoggedIn(false);
    } catch (error) {
      if(error.message !== 'Network Error'){
        handleError(error.response.data?.message)
      }else{
        handleError('Network Error')
      }
    }
  }

  const navList = (
    <List className="flex flex-row text-white">
      <Link to='/chat' >
        <ListItem className="hover:text-green-500 hover:bg-white">
          <ListItemPrefix>
            <IoMdChatbubbles className="h-5 w-5" />
          </ListItemPrefix>
          Inbox
        </ListItem>
      </Link>
      <Link to='/template' >
        <ListItem className="hover:text-green-500 hover:bg-white">
          <ListItemPrefix>
            <BiSolidMessageEdit className="h-5 w-5" />
          </ListItemPrefix>
          Template
        </ListItem>
      </Link>
      <Link to='/customers' >
        <ListItem className="hover:text-green-500 hover:bg-white">
          <ListItemPrefix>
            <IoMdContacts className="h-5 w-5" />
          </ListItemPrefix>
          Customers
        </ListItem>
      </Link>
      <Link to='/settings' >
        <ListItem className="hover:text-green-500 hover:bg-white">
          <ListItemPrefix>
            <IoMdSettings className="h-5 w-5" />
          </ListItemPrefix>
          Settings
        </ListItem>
      </Link>
      <button onClick={Logout} >
        <ListItem className="hover:text-green-500 hover:bg-white">
          <ListItemPrefix>
            <TbLogout className="h-5 w-5" />
          </ListItemPrefix>
          Log Out
        </ListItem>
      </button>
    </List>
  );
  return (
    <div className="w-full rounded-none px-4 py-2 lg:px-8 lg:py-4 h-full flex items-center justify-between text-white bg-green-500 shadow-lg">
      <div>
        <Link to='/'>
          <Typography
            variant="h5"
            className="mr-4 cursor-pointer py-1.5 font-medium flex gap-2 items-center"
          >
            <img
            src={logo}
            alt="logo"
            className="h-8 w-8"
            />
            <p className="font-semibold">
                ChatApp
            </p>
          </Typography>
        </Link>
      </div>
      <div className="flex items-center gap-4">
        <div className="mr-4 hidden lg:block">{navList}</div>
        <div className="flex items-center gap-x-1">
        </div>
      </div>
      <div className='absolute md:right-5 z-10 bottom-5 p-5 md:w-[21%] w-full'>
        <Alert open={alert} color="red">
          <p className='font-bold'>
            Error
          </p>
          <p>
            Message : {alertMessage}
          </p>
        </Alert>
      </div>
  </div>
  )
}

export default NavBar