import { Input, Tooltip, Stepper, Step, Button, Select, Option, Alert } from '@material-tailwind/react'
import React, { useState } from 'react'
import { IoInformationCircleSharp, IoCheckmarkDoneOutline, IoSettingsSharp, IoWarningOutline } from 'react-icons/io5'
import { FaUser } from "react-icons/fa";
import { useEffect } from 'react';
import API from '../API/API';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from '../useDocumentTitle';

const InputCustom = ({value, disabled, onChange, label, type, placeholder, error, autoComplete}) =>{
    return(
        <div className='space-y-1'>
            <p className='text-sm'>
                {label}
            </p>
            <Input
            className={`!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-2 ${error ? 'ring-red-800 focus:ring-red-800' : 'ring-transparent'} placeholder:text-gray-500`}
            labelProps={{
                className: "hidden",
            }}
            containerProps={{ className: "min-w-[100px]" }}
            disabled={disabled}
            value={value}
            onChange={onChange}
            type={type}
            placeholder={placeholder}
            autoComplete={autoComplete}
            />
        </div>
    )
  }

const Register = ({mode}) => {
    useDocumentTitle('Sign Up - ChatApp')
    const [activeStep, setActiveStep] = useState(0);
    const [isLastStep, setIsLastStep] = useState(false);
    const [isFirstStep, setIsFirstStep] = useState(false);
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [passConfirm, setPassConfirm] = useState('')
    const [email, setEmail] = useState('')
    const [division, setDivision] = useState('')
    const [role, setRole] = useState('')
    const [accountList, setAccountList] = useState([])
    const [account, setAccount] = useState('')
    const navigate = useNavigate()
    //=====================ERROR HANDLING===================
    const [alert, setAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')

    const handleError = (message) =>{
    setAlert(true)
    setAlertMessage(message)
    }

    if(alert){
    setTimeout(() => {
        setAlert(false)
    }, 5000);
    }
    const regex = {
        username: /(^[a-zA-Z0-9]+$)/,
        password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*.-]).{8,}$/
    }
    let disableButton = false
    switch(activeStep){
        case 0:
            if(!username || !password  || !passConfirm || !email || (password&&!password.match(regex.password)) || (username && !username.match(regex.username)) || (passConfirm && password !== passConfirm)){
                disableButton = true
            }
        break
        case 1:
            if(!role || !division || !account){
                disableButton = true
            }
        break
        default:
        break
    }

    const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
    const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

    useEffect(() => {
    const getAccList = async() => {
      try {
        const response = await API.get('/api/auth/account-list')
        if(response.status === 200){
            setAccountList(response.data)
        }
      } catch (error) {
        if(error.message !== 'Network Error'){
          handleError(error.response.data?.message)
        }else{
          handleError('Network Error')
        }
      }
    }
      getAccList()
    }, [])

    let data = {
      username: username,
      password: password,
      passwordConfirm: passConfirm,
      roles: role,
      division: division,
      account: account,
      email: email
    }
    const Register = async () => {
      await API.post('/api/auth/signup', data).then((response) =>{
        if(response.status === 200){
          // console.log(response.data)
          if(mode){
              mode()
          }else{
              navigate('/login');
          }
        }
      }).catch((error) =>{
        console.log(error)
      })
    }

  return (
    <div className="flex flex-col items-center justify-center mx-auto md:py-0 text-gray-800">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8 min-h-[580px] flex flex-col justify-between">
                <div className='space-y-4 md:space-y-6'>
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        Register New Account
                    </h1>
                    <div className="w-full">
                        <Stepper
                            activeStep={activeStep}
                            isLastStep={(value) => setIsLastStep(value)}
                            isFirstStep={(value) => setIsFirstStep(value)}
                        >
                            <Step onClick={() => setActiveStep(0)}>
                                <FaUser className="h-5 w-5" />
                            </Step>
                            <Step onClick={() => setActiveStep(1)}>
                                <IoSettingsSharp className="h-5 w-5" />
                            </Step>
                            <Step onClick={() => setActiveStep(2)}>
                                <IoCheckmarkDoneOutline className="h-5 w-5" />
                            </Step>
                        </Stepper>
                    </div>
                </div>
                <div className="space-y-4 md:space-y-5 min-h-[390px] flex flex-col justify-start">
                    {activeStep === 0 && (
                        <>
                            <p className='text-lg font-semibold -mb-4'>Account Information</p>
                            <InputCustom value={email} onChange={(e) => setEmail(e.target.value)} label={'Email'} placeholder="abcdef@mail.com" type={'email'} />
                            <div>
                                <InputCustom autoComplete={'off'} value={username} onChange={(e) => setUsername(e.target.value)} label={'Username'} placeholder="Username" error={username && !username.match(regex.username)}/>
                                {username && username && !username.match(regex.username) && (
                                <div className='text-xs font-bold mt-1'>
                                    Invalid username. Usernames must:
                                    <div className='ml-3'>
                                        <p>• Start with a letter</p>
                                        <p>• Only contains alphabet, number, _, - (dash), and .(dot)</p>
                                    </div>
                                </div>
                                )}
                            </div>
                            <div>
                            <div>
                                <InputCustom autoComplete={'new-password'} type="password" placeholder="••••••••" label='Password' value={password} onChange={(e) => setPassword(e.target.value)} error={password && !password.match(regex.password)} />
                                {password.length > 5 && !password.match(regex.password)
                                ? 
                                <div className='text-xs font-bold mt-1'>
                                    Invalid Password. Password must contain:
                                    <div className='ml-3'>
                                        <p>• At least one upper case letter</p>
                                        <p>• At least one lower case letter</p>
                                        <p>• At least one digit of number</p>
                                        <p>• At least one special character(#?!@$%^&*.-)</p>
                                        <p>• At least 8 characters</p>
                                    </div>
                                </div>
                                :
                                <p className='text-xs text-gray-700 flex items-center mt-0.5 gap-1'>
                                    <IoInformationCircleSharp className='text-base'/>
                                    Use at least 8 characters.
                                </p>
                                }
                            </div>
                            </div>
                            <div>
                            <InputCustom type="password" placeholder="••••••••" label='Confirm your password' error={passConfirm && password !== passConfirm} value={passConfirm} onChange={(e) => setPassConfirm(e.target.value)}/>
                            {passConfirm && password !== passConfirm && (
                                <p className='text-xs font-bold text-red-500 flex items-center mt-1.5 gap-1'>
                                    <IoWarningOutline className='text-base'/>
                                    Password does not match 
                                </p>
                            )}
                            </div>
                        </>
                    )}
                    {activeStep === 1 && (
                        <>
                            <p className='text-lg font-semibold -mb-4'>Account Detail</p>
                            <div className='space-y-1'>
                                <p className='text-sm'>
                                    Select Division
                                </p>
                                <Select value={division} onChange={setDivision} className='!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900' 
                                    labelProps={{
                                    className: "hidden",
                                    }}
                                    >
                                    <Option value='hrd'>HRD</Option>
                                    <Option value='marketing'>Marketing</Option>
                                    <Option value='cs'>Customer Service</Option>
                                </Select>
                            </div>
                            <div className='space-y-1'>
                                <p className='text-sm'>
                                    Select Role
                                </p>
                                <Select value={role} onChange={setRole} className='!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900' 
                                    labelProps={{
                                    className: "hidden",
                                    }}
                                    >
                                    <Option value='manager'>Manager</Option>
                                    <Option value='supervisor'>Supervisor</Option>
                                    <Option value='project manager'>Project Manager</Option>
                                    <Option value='agent'>Agent</Option>
                                </Select>
                            </div>
                            <div className='space-y-1'>
                                <p className='text-sm'>
                                    Select Whatsapp Account
                                </p>
                                <Select value={account.numberPhone?.toString()} onChange={e => setAccount(JSON.parse(e))} className='!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900' 
                                    labelProps={{
                                    className: "hidden",
                                    }}
                                    >
                                    {accountList && accountList.map((acc, index) => {
                                        return(
                                            <Option key={index} value={JSON.stringify(acc)}>
                                                {acc.numberPhone} - {acc.name}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            </div>
                        </>
                    )}
                    {activeStep === 2 && (
                        <>
                            <p className='text-lg font-semibold -mb-4'>Confirm Account Detail</p>
                            <div className='space-y-2'>
                                <div className=''>
                                    <p className='font-bold'>Username</p> {username}
                                </div>
                                <div className=''>
                                <p className='font-bold'>Email</p> {email}
                                </div>
                                <div className=''>
                                <p className='font-bold'>Division</p> <p className='uppercase'>{division}</p>
                                </div>
                                <div className='capitalize'>
                                <p className='font-bold'>Role</p> {role}
                                </div>
                                <div className=''>
                                <p className='font-bold'>Account</p> {account.numberPhone} - {account.name}
                                </div>
                            </div>                        
                        </>
                    )}
                </div>
                    <div className="flex justify-between">
                        <Button onClick={handlePrev} disabled={isFirstStep || disableButton}>
                            Prev
                        </Button>
                        {activeStep === 2 ?
                        <Tooltip content="Click to Sign in" placement='bottom'>
                            <Button onClick={Register}>
                                Register
                            </Button>
                        </Tooltip>
                        : 
                        <Button onClick={handleNext} disabled={isLastStep || disableButton}>
                            Next
                        </Button>
                        }
                    </div>
            </div>
        </div>
        <div className='absolute md:right-5 z-10 bottom-5 p-5 md:w-[21%] w-full'>
            <Alert open={alert} color="red">
            <p className='font-bold'>
                Error
            </p>
            <p>
                Message : {alertMessage}
            </p>
            </Alert>
        </div>
    </div>
  )
}

export default Register