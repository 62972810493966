import { IconButton, Option, Select, Dialog, DialogBody, Button, Textarea, Switch, Alert} from '@material-tailwind/react';
import React, { useEffect, useState } from 'react'
import { IoCloseCircle } from "react-icons/io5";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Tooltip } from '@mui/material';
import { IoIosArrowForward } from "react-icons/io";
import API from '../API/API';
import { useStateContext } from '../contexts/ContextProvider';
import avatar from '../img/default_avatar.svg'
import agent from '../img/agent.png'


const ChatInfo = ({selectedChat, handleInfo, socket, setSelectedChat}) =>{
    const[openNotes, setOpenNotes] = useState(false)
    const[chatBot, setChatBot] = useState(selectedChat?.botChat)
    const[notes, setNotes] = useState(selectedChat?.notes)
    const[tag, setTag] = useState(selectedChat?.tags)
    const[status, setStatus] = useState(selectedChat?.status)
    const[agentList, setAgentList] = useState('')
    const[newAgent, setNewAgent] = useState('')
    const handleOpenNotes = () => setOpenNotes(!openNotes)
    const{setActiveListChat} = useStateContext()
    const handleBackToChatList = () => {
      setSelectedChat(null);
      setActiveListChat(true);
    };
    const handleChangeNewAgent = (value) => {
      const data = JSON.parse(value)
      setNewAgent(data)
    }
    let data = {
      conversationId: selectedChat?.id,
      botChat: chatBot,
      tags: tag,
      notes: notes,
      status: status,
    }

    const updateConversation = () =>{
      socket.emit('updateConversation', data, (response) =>{
        if(response){
          setChatBot(selectedChat?.botChat)
          setNotes(selectedChat?.notes)
          setTag(selectedChat?.tags)
          setStatus(selectedChat?.status)
          setNewAgent('')
        }
      });
    }

    useEffect(() => {
      setChatBot(selectedChat?.botChat)
      setNotes(selectedChat?.notes)
      setTag(selectedChat?.tags)
      setStatus(selectedChat?.status)
      setNewAgent('')
    }, [selectedChat]);

    //=====================ERROR HANDLING===================
    const [alert, setAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')

    const handleError = (message) =>{
      setAlert(true)
      setAlertMessage(message)
    }

    if(alert){
      setTimeout(() => {
      setAlert(false)
      }, 5000);
    }
    useEffect(() => {
      const getAgentList = async () =>{
        try {
          const response = await API.get('/api/agentList')
          if(response.status === 200){
            setAgentList(response.data)
          }
        } catch (error) {
          console.log(error.response)
          if(error.message !== 'Network Error'){
            handleError(error.response.data?.message)
          }else{
            handleError('Network Error')
          }
        }
      }
      getAgentList()
    }, []);

    const transferConversation = () =>{
      socket.emit('transferConversation', {
        newAgentId: newAgent.agent,
        currentAgentId: selectedChat?.agentId,
        conversationId: selectedChat?.id
      }, (response) =>{
        if(response){
          handleBackToChatList()
        }
      });
    }

    return(
      <div className='w-full lg:w-[20%] h-full'>
        <div className='flex items-center justify-between bg-white gap-5 p-5 border-b border-l border-gray-200 rounded-b-xl h-20'>
          <p className='font-semibold'>
            Chat & Customer Info
          </p>
          <IconButton className='block text-xl' variant='text' onClick={handleInfo}>
            <IoCloseCircle/>
          </IconButton>
        </div>
        <div className='h-[90%] overflow-y-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] space-y-2 bg-gray-100 py-5'>
          <div className='bg-white p-5 border-y border-gray-200 space-y-5 rounded-xl'>
            <div className='flex items-center gap-5'>
              {/* <div className='h-16 w-16 bg-gray-500 p-3 text-white rounded-full flex justify-center items-center'>
                <IoPersonOutline className='text-3xl'/>
              </div> */}
              <img alt='' src={avatar} className='h-16 rounded-full bg-[#F9E8C9]' />
              <div className='space-y-1 text-gray-800'>
                <p className='font-bold'>
                  {selectedChat?.name}
                </p>
                <p>
                  {selectedChat?.numberPhone}
                </p>
              </div>
            </div>
            {selectedChat?.startChat === false && (
              <div className='rounded-lg bg-gray-200 p-5 flex justify-between items-center'>
                <p className='text-red-700'>
                  Customer Service Window is expired
                </p>
                <Tooltip title={<p className='text-sm'>This session has expired because user has not replied within 24 hours. You can follow up using paid message template to re-initiate the conversation.</p>}>
                  <p>
                    <IoIosInformationCircleOutline className='text-xl'/>
                  </p>
                </Tooltip>
              </div>
            )}
          </div>
          <div className='bg-white p-5 border-y border-gray-200 space-y-3 rounded-xl text-gray-800'>
            <div className='flex items-center gap-5'>
              <p className='text-lg font-bold'>
                Conversation Properties
              </p>
            </div>
            <div className='w-full space-y-3'>
              <div className='w-full flex items-center h-10 rounded-lg px-2 transition-all hover:bg-gray-100 cursor-pointer' onClick={handleOpenNotes}>
                <div className='w-full flex justify-start'>
                  <p>Notes</p>
                </div>
                <div className='w-full flex justify-end'>
                  <IoIosArrowForward/>
                </div>
              </div>
              <div className='w-full h-14 rounded-lg px-2 transition-all gap-5'>
                <p>Tags</p>
                <Select value={tag} onChange={setTag} className='!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900' 
                labelProps={{
                  className: "hidden"
                }}>
                  <Option value='req_quotation'>Req Quotation</Option>
                  <Option value='tunggu_transfer'>Tunggu Transfer</Option>
                  <Option value='diperoses'>Diperoses</Option>
                  <Option value='refund'>Refund</Option>
                  <Option value='selesai'>Selesai</Option>
                  <Option value='perlu_bantuan'>Perlu Bantuan</Option>
                </Select>
              </div>
              <div className='w-full h-14 rounded-lg px-2 transition-all  gap-5'>
                <p>Status</p>
                <Select value={status} onChange={setStatus} className='!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900' 
                labelProps={{
                  className: "hidden"
                }}>
                  <Option value='unserved' disabled={status === 'served'|| status === 'resolved'}>Unserved</Option>
                  <Option value='served'>Served</Option>
                  <Option value='resolved'>Resolved</Option>
                </Select>
              </div>
              <div className='w-full flex items-center h-10 rounded-lg px-2 transition-all '>
                <div className='w-full flex justify-start'>
                  <p>ChatBot</p>
                </div>
                <div className='w-full flex justify-end'>
                  <Switch
                    checked={chatBot}
                    id="custom-switch-component"
                    ripple={false}
                    className="h-full w-full checked:bg-gray-900"
                    containerProps={{
                      className: "w-11 h-6",
                    }}
                    circleProps={{
                      className: "before:hidden left-0.5 border-none",
                    }}
                    onChange={(e) => setChatBot(e.target.checked)}
                  />
                </div>
              </div>
              <div className='w-full flex items-center justify-end'>
                <Button className='h-8 p-3 flex items-center justify-center' disabled={chatBot === selectedChat?.botChat && tag === selectedChat?.tags && notes === selectedChat?.notes && status === selectedChat?.status} onClick={updateConversation}>
                  Save
                </Button>
              </div>
            </div>
          </div>
          <div className='bg-white p-5 border-y w-full border-gray-200 space-y-3 rounded-xl text-gray-800'>
            <div className='flex items-center gap-5'>
              <p className='text-lg font-bold'>
                Agent Info
              </p>
            </div>
            <div className='flex items-center gap-5'>
              {/* <div className='h-16 w-16 bg-gray-500 p-3 text-white rounded-full flex justify-center items-center'>
                <RiCustomerService2Fill className='text-xl'/>
              </div> */}
              <img src={agent} alt='agent.png' className='h-16 rounded-full' />
              <div className='space-y-0.5 text-gray-800'>
                <p className='font-bold'>
                  {selectedChat?.agentName}
                </p>
                <p>
                  {selectedChat?.agentEmail}
                </p>
                <div className='flex gap-1'>
                  <p className='uppercase'>{selectedChat?.agentDivision}</p>
                </div>
              </div>
            </div>
            <div className='space-y-2'>
              <p className='font-semibold'>
                Transfer Conversation
              </p>
              <div className='w-full space-y-4'>
                <div className='space-y-1'>
                  <p className='font-semibold text-sm'>
                    Select new agent
                  </p>
                  <Select className='!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900' 
                    labelProps={{
                      className: "hidden"
                    }} onChange={handleChangeNewAgent}>
                      {agentList && agentList.map((value, index) =>{
                        const username = value.username
                        return(
                          <Option key={value.id} value={JSON.stringify(value)} disabled={username === selectedChat?.agentName}>{username}</Option>
                        )
                      })}
                  </Select>
                </div>
                <div className='mt-2'>
                  <Button className='w-full' disabled={!newAgent} onClick={transferConversation}>
                    Transfer
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog open={openNotes} handler={handleOpenNotes} size='sm'>
          <DialogBody>
            <div className='space-y-5'>
              <div>
                <p className='text-xl font-semibold'>
                  Add Notes
                </p>
              </div>
              <div className='px-10 space-y-3'>
                <p className='text-center'>
                  You can add conversation notes in this session in the form of chat summaries with customers, chat context as a reference, etc.
                </p>
                <div>
                  <Textarea  className='!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900' 
                  labelProps={{
                    className: "hidden",
                  }} onChange={(e) => setNotes(e.target.value)} value={notes}/>
                </div>
              </div>
            </div>
          </DialogBody>
        </Dialog>
        <div className='absolute lg:right-5 z-10 bottom-5 p-5 lg:w-[21%] w-full'>
          <Alert open={alert} color="red">
            <p className='font-bold'>
              Error
            </p>
            <p>
              Message : {alertMessage}
            </p>
          </Alert>
        </div>
      </div>
    )
}

export default ChatInfo